import React, { useCallback } from 'react';

const List = ({ worksheet}) => {

    return (
        <div className='flex-grow h-full'>
            LIST
        </div>
    );
};

export default List;