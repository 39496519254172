import React, { useState, useCallback, Fragment, useRef } from 'react';
import Items from '../Items/items';
import { Droppable } from 'react-beautiful-dnd';
import NewItem from '../Items/newItem';
import { Menu, Transition } from '@headlessui/react'
import { ListStartIcon, ListEndIcon, EllipsisIcon } from 'lucide-react';

const MAX_COLUMN_NAME_LENGTH = 35;

const Column = ({ column, items, columnIndex, addItem, updateItem, updateColumnName, removeColumn, deleteItem, markUnsaved, uniqueTags, uniqueAssignees }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [addToTop, setAddToTop] = useState(true);

    const columnNameRef = useRef(null);

    const handleColumnNameFocus = () => {
        setIsEditing(true);
    };

    const handleColumnNameBlur = useCallback((event) => {
        const newName = event.target.textContent.trim().slice(0, MAX_COLUMN_NAME_LENGTH);
        event.target.textContent = newName;
        updateColumnName(column.index, newName);
        markUnsaved();
        setIsEditing(false);
    }, [updateColumnName, column.index, markUnsaved]);

    const handleAddItem = useCallback((itemData) => {
        addItem(column.index, itemData, addToTop);
        markUnsaved();
    }, [addItem, column.index, markUnsaved, addToTop]);

    const handleEditItem = useCallback((itemData) => {
        updateItem(column.index, itemData);
        markUnsaved();
    }, [updateItem, column.index, markUnsaved]);

    const handleDeleteItem = useCallback((itemId) => {
        deleteItem(column.index, itemId);
        markUnsaved();
    }, [deleteItem, column.index, markUnsaved]);

    const openNewItemModal = (toTop) => {
        setEditingItem(null);
        setAddToTop(toTop);
        setModalOpen(true);
    };

    const openEditItemModal = (item) => {
        setEditingItem(item);
        setModalOpen(true);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();
        }
        const currentLength = event.target.textContent.length;
        if (currentLength >= MAX_COLUMN_NAME_LENGTH && event.key.length === 1) {
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const text = event.clipboardData.getData('text/plain');
        const currentLength = event.target.textContent.length;
        const availableSpace = MAX_COLUMN_NAME_LENGTH - currentLength;
        const insertText = text.replace(/\n/g, '').slice(0, availableSpace);
        document.execCommand('insertText', false, insertText);
    };

    const handleDeleteColumn = useCallback(() => {
        removeColumn(column.index);
        markUnsaved();
    }, [removeColumn, column.index, markUnsaved]);

    return (
        <div className="flex flex-col w-72 min-w-72 max-w-72 px-2.5 rounded-lg border border-transparent">
            <div className="flex justify-between items-start">
                <div
                    ref={columnNameRef}
                    className={`py-4 pr-4 text-md font-semibold leading-tight text-zinc-900 focus:ring-0 focus:outline-none max-w-[calc(100%-80px)] ${
                        isEditing 
                            ? 'whitespace-pre-wrap break-words' 
                            : 'truncate'
                    }`}
                    contentEditable
                    suppressContentEditableWarning
                    onFocus={handleColumnNameFocus}
                    onBlur={handleColumnNameBlur}
                    onKeyPress={handleKeyPress}
                    onPaste={handlePaste}
                    title={column.name}
                >
                    {column.name}
                </div>

                <div className="flex items-center h-full py-4">
                    <button
                        className="mr-2 p-1 rounded-md text-zinc-500 hover:text-zinc-900 hover:bg-zinc-100 focus:outline-none focus:ring-0"
                        onClick={() => openNewItemModal(true)}
                        title="Add card to top"
                    >
                        <ListStartIcon className="w-4 h-4 stroke-current" />
                    </button>

                    <Menu as="div" className="relative flex items-center h-full text-left">
                        <Menu.Button className="p-1 flex items-center rounded-md text-zinc-600 hover:text-zinc-900 hover:bg-zinc-100 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0">
                        <span className="sr-only">Open options</span>
                        <EllipsisIcon className="h-4 w-4 stroke-zinc-500" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                        >
                        <Menu.Items className="absolute right-0 z-10 mt-14 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                            {({ active }) => (
                                <button
                                className={`${
                                    active ? 'bg-zinc-100 rounded-md text-zinc-900' : 'text-zinc-700'
                                } group flex w-full items-center px-4 py-2 text-sm`}
                                onClick={handleDeleteColumn}
                                >
                                Delete Column
                                </button>
                            )}
                            </Menu.Item>
                        </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
            <Droppable droppableId={column.index.toString()} key={column.index.toString()} type="COLUMN">
                {(provided) => (
                <div
                    className="flex flex-col min-h-16"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    <Items items={items} columnIndex={columnIndex} onEditItem={openEditItemModal} />
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
            {items.length > 0 && (
                <button
                    className="flex w-full items-start justify-left rounded-lg p-2.5 text-xs font-semibold text-zinc-400 text-center ring-0 focus:ring-0 focus:outline-none border border-transparent hover:border-zinc-200 hover:border hover:bg-zinc-50 hover:text-zinc-500 transition-colors"
                    onClick={() => openNewItemModal(false)}
                >
                    <ListEndIcon className="w-4 h-4 mr-2 stroke-zinc-400" />
                    Add card
                </button>
            )}
            <NewItem
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onSave={editingItem ? handleEditItem : handleAddItem}
                onDelete={handleDeleteItem}
                item={editingItem}
                columnIndex={columnIndex}
                uniqueTags={uniqueTags}
                uniqueAssignees={uniqueAssignees} 
            />
        </div>
    );
};

export default Column;
