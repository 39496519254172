import React, { useState, useEffect, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

const predefinedColors = [
    { name: "Blue", hex: "#4285F4", argb: "FF4285F4" },
    { name: "Teal", hex: "#47BCC6", argb: "FF47BCC6" },
    { name: "Green", hex: "#34A753", argb: "FF34A753" },
    { name: "Purple", hex: "#881798", argb: "FF881798" },

    { name: "Red", hex: "#EA4336", argb: "FFEA4336" },
    { name: "Orange", hex: "#FF6D01", argb: "FFFF6D01" },
    { name: "Yellow", hex: "#FBBB07", argb: "FFFBBB07" },
    { name: "Black", hex: "#242424", argb: "FF242424" },
    
    { name: "Blue Light", hex: "#DAE6FD", argb: "FFDAE6FD" },
    { name: "Teal Light", hex: "#DAF1F3", argb: "FFDAF1F3" },
    { name: "Green Light", hex: "#D1F1DA", argb: "FFD1F1DA" },
    { name: "Purple Light", hex: "#d9a7e0", argb: "FFd9a7e0" },

    { name: "Red Light", hex: "#FADAD7", argb: "FFFADAD7" },
    { name: "Orange Light", hex: "#F9CB9C", argb: "FFF9CB9C" },
    { name: "Yellow Light", hex: "#FEF2CD", argb: "FFFEF2CD" }
    // { name: "Black Light", hex: "#d6d6d6", argb: "FFd6d6d6" }
];

// USE LIKE THIS
//<ColorPicker onChange={handleFormChange('leftBorderColor')} format="ARGB" defaultColor={itemData.leftBorderColor} variant="inline" />
//<ColorPicker onChange={handleFormChange('leftBorderColor')} format="ARGB" defaultColor={itemData.leftBorderColor} variant="fold-out" />

const ColorPicker = ({ onChange, format = "HEX", defaultColor = "#E5E7EB", variant = "fold-out" }) => {
    const convertArgbToHex = (argb) => {
        return `#${argb.slice(2)}`;
    };

    const convertHexToArgb = (hex) => {
        return `FF${hex.slice(1)}`;
    };

    const getDefaultColor = () => {
        if (defaultColor === "") return "";
        if (defaultColor.startsWith("#")) {
            return defaultColor;
        } else {
            return convertArgbToHex(defaultColor);
        }
    };

    const [selectedColor, setSelectedColor] = useState(getDefaultColor());

    useEffect(() => {
        setSelectedColor(getDefaultColor());
    }, [defaultColor, format]);

    const handleColorChange = (color) => {
        if (color === "") {
            setSelectedColor("");
            onChange("");
        } else {
            setSelectedColor(color.hex);
            onChange(format === "HEX" ? color.hex : color.argb);
        }
    };

    const renderColorButton = (color) => (
        <button
            key={color ? color.name : "no-color"}
            type="button"
            className={`w-7 h-7 rounded-full focus:outline-none ${selectedColor === (color ? color.hex : "") ? 'ring-2 ring-offset-2 ring-green-500' : ''}`}
            style={color ? { backgroundColor: color.hex } : { backgroundColor: 'white', border: '2px solid #d1d5db', position: 'relative' }}
            onClick={() => handleColorChange(color || "")}
        >
            {!color && (
                <span className="absolute inset-0 flex items-center justify-center text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                    </svg>
                </span>
            )}
        </button>
    );

    const renderColorGrid = () => (
        <div className="max-w-80 grid grid-cols-4 sm:grid-cols-6 md:grid-cols-8 gap-2 p-2">
            {renderColorButton()}
            {predefinedColors.map(color => renderColorButton(color))}
        </div>
    );

    if (variant === "inline") {
        return renderColorGrid();
    }

    return (
        <div className="flex align-middle rounded-md">
            <Menu as="div" className="relative flex align-middle">
                <Menu.Button className="flex items-center justify-center w-6 h-6 ring-1 ring-inset ring-black ring-opacity-10 rounded-full" style={selectedColor ? { backgroundColor: selectedColor } : { backgroundColor: 'white', border: '2px solid #d1d5db' }}>
                    {!selectedColor && (
                        <span className="text-zinc-400">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </span>
                    )}
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg focus:outline-none">
                        {renderColorGrid()}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

export default ColorPicker;
