import React, { useState } from 'react';

const Checkbox = ({ label, initialState = false, editable = false, onToggle, onTextChange }) => {
    const [isChecked, setIsChecked] = useState(initialState);
    const [text, setText] = useState(label);

    const toggleCheckbox = () => {
        if (editable && text !== "") {
            setIsChecked(!isChecked);
            onToggle(!isChecked, text);  // Pass the new state and text to onToggle
        }
    };

    const handleTextChange = (e) => {
        const newText = e.target.value;
        setText(newText);
        if (editable) {
            onTextChange(newText);  // Call onTextChange instead of onToggle
        }
    };

    return (
        <div className="flex items-center space-x-2 min-w-80">
            <div
                className={`w-4 h-4 rounded-full flex justify-center items-center shrink-0 cursor-pointer
                            ${isChecked ? 'bg-green-500 border-green-500' : 'bg-white border-zinc-300'}
                            border-2 transition-colors`}
                onClick={toggleCheckbox}
            >
                {isChecked && (
                    <svg className="w-3 h-3 text-white fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" />
                    </svg>
                )}
            </div>
            {editable ? (
                <input
                    type="text"
                    value={text}
                    onChange={handleTextChange}
                    className="block w-full border-0 text-sm focus:ring-0 focus:outline-none"
                    placeholder="Add a new todo"
                />
            ) : (
                <span className={isChecked ? "text-zinc-400 line-through text-sm" : "text-zinc-900 text-sm"}>
                    {text}
                </span>
            )}
        </div>
    );
};

export default Checkbox;