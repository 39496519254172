import React, { useState, useEffect, useRef } from 'react';
import { UserIcon, XIcon } from 'lucide-react';

const Assignees = ({ cellContent, onChange, editable, uniqueAssignees = [] }) => {
    const parseAssignees = (content) => {
        if (!content) return [];
        return content.split(':').filter(assignee => assignee.trim() !== '').map(assignee => assignee.trim());
    };

    const [assignee, setAssignee] = useState(parseAssignees(cellContent)[0] || '');
    const [inputValue, setInputValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
        setAssignee(parseAssignees(cellContent)[0] || '');
    }, [cellContent]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSetAssignee = (newAssignee) => {
        setAssignee(newAssignee);
        setInputValue('');
        setShowSuggestions(false);
        setSelectedSuggestionIndex(-1);
        updateParent(newAssignee);
    };

    const handleRemoveAssignee = () => {
        setAssignee('');
        updateParent('');
    };

    const handleKeyDown = (e) => {
        const suggestions = getSuggestions();
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedSuggestionIndex(prev => (prev < suggestions.length - 1 ? prev + 1 : prev));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedSuggestionIndex(prev => (prev > 0 ? prev - 1 : prev));
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < suggestions.length) {
                handleSetAssignee(suggestions[selectedSuggestionIndex]);
            } else if (inputValue.trim()) {
                handleSetAssignee(inputValue.trim());
            }
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        setShowSuggestions(value.length > 0);
        setSelectedSuggestionIndex(-1);
    };

    const updateParent = (updatedAssignee) => {
        onChange(updatedAssignee ? `:${updatedAssignee}` : null);
    };

    const getSuggestions = () => {
        if (!Array.isArray(uniqueAssignees)) return [];
        return uniqueAssignees.filter(a => 
            a.toLowerCase().includes(inputValue.toLowerCase()) && 
            a.toLowerCase() !== assignee.toLowerCase()
        );
    };

    if (!editable && !assignee) {
        return null;
    }

    const suggestions = getSuggestions();

    return (
        <div 
            ref={componentRef} 
            className={`relative flex items-center gap-1 ${editable ? 'p-1 border border-zinc-200 rounded-md' : ''}`}
        >
            {assignee && (
                <div className={`inline-flex items-center gap-x-[1px] text-xs text-zinc-500 ${editable ? 'py-1 font-medium rounded-full px-2.5 bg-zinc-100' : ''}`}>
                    <UserIcon className="w-3 h-3 stroke-zinc-500" />
                    <span>{assignee}</span>
                    {editable && (
                        <button
                            onClick={handleRemoveAssignee}
                            className="text-zinc-400 hover:text-zinc-600 ml-0.5 transition-colors duration-200"
                            aria-label="Remove assignee"
                        >
                            <XIcon className="w-3.5 h-3.5 stroke-zinc-400" />
                        </button>
                    )}
                </div>
            )}
            {editable && (
                <div className="flex-grow">
                    <input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onFocus={() => setShowSuggestions(true)}
                        className="bg-transparent focus:outline-none px-1.5 py-1.5 text-sm w-full"
                        placeholder={assignee.length === 0 ? "Add an assignee..." : "Re-assign..."}
                    />
                    {showSuggestions && (
                        <div
                            ref={dropdownRef}
                            className="absolute left-0 right-0 mt-1 bg-white border border-zinc-200 rounded-md shadow-lg text-sm z-10 max-h-48 overflow-y-auto transition-all duration-300 ease-in-out transform origin-top"
                        >
                            {suggestions.length > 0 ? (
                                suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className={`p-1.5 cursor-pointer transition-colors duration-200 ${
                                            index === selectedSuggestionIndex ? 'bg-zinc-100' : 'hover:bg-zinc-50'
                                        }`}
                                        onClick={() => handleSetAssignee(suggestion)}
                                    >
                                        {suggestion}
                                    </div>
                                ))
                            ) : (
                                <div className="p-2 text-zinc-500 italic">
                                    No suggestions available
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Assignees;
