import { v4 as uuidv4 } from 'uuid';
import { parseRichTextForExcel } from '../Utilities//RichTextUtils';  // Import utilities
import { parse, format } from 'date-fns';

// ----- COLUMNS -----

export const columnIndexToLetter = (index) => {
    let letter = '', temp;
    while (index > 0) {
        temp = (index - 1) % 26;
        letter = String.fromCharCode(temp + 65) + letter;
        index = (index - temp - 1) / 26;
    }
    return letter;
};





// ----- ITEMS -----

export const parseItemsInColumn = async (worksheet, columnIndex, config) => {
    let itemsFound = [];
    let potentialItem = [];
    try {
        for (let rowIndex = 2; rowIndex <= worksheet.rowCount; rowIndex++) {
            const cell = worksheet.getCell(rowIndex, columnIndex);
            
            if (matchesConfig(cell, config)) {
                potentialItem.push(cell);
            } else {
                if (potentialItem.length !== 0) {
                    const item = createNewItemFromCells(potentialItem, columnIndex, config);
                    itemsFound.push(item);
                }
                potentialItem = []; // Reset potentialItem regardless of whether it turned into an item or not
            }
        }
        // Handle any remaining potential item after the loop finishes
        if (potentialItem.length !== 0) {
            const item = createNewItemFromCells(potentialItem, columnIndex, config);
            itemsFound.push(item);
        }
    } catch (err) {
        console.error("Failed to parse column items:", err);
        throw err;
    }
    return itemsFound;
};


// Checks if a cell matches the required configuration
const matchesConfig = (cell, config) => {
    // return cell.border?.style === config.borderStyle.style && cell.text;
    return cell.text; 
};

export const createNewItemFromCells = (cells, columnIndex) => {
    const item = {
        id: uuidv4(),
        state: columnIndex,
        leftBorderColor: cells[0].border?.left?.style && cells[0].border.left.color ? `${cells[0].border.left.color.argb}` : "00000",
        bgColor: cells[0].fill?.fgColor?.argb ? `#${cells[0].fill.fgColor.argb.slice(2)}` : "fffff",
        contentCells: cells.map(cell => {
            const cellType = determineCellType(cell.text);
            let cellContent;

            if (cellType === 'date') {
                cellContent = {
                    text: formatDateToMMDDYYYY(cell.text),
                    type: 'date'
                };
            } else if (cell.model.value.richText) { // Rich text
                cellContent = {
                    richText: cell.model.value.richText,
                    type: "text"
                };
            } else if (cell.text.match(/<\/?[a-z][\s\S]*>/i)) { // HTML - turns HTML content into rich text
                cellContent = {
                    richText: parseRichTextForExcel(cell.text),
                    type: "text"
                };
            } else {
                cellContent = {
                    text: cell.text || "",
                    type: cellType
                };
            }

            return {
                ...cellContent,
                bgColor: cell.fill?.fgColor?.argb ? `#${cell.fill.fgColor.argb.slice(2)}` : "fffff",
                leftBorderColor: cell.border?.left?.style && cell.border.left.color ? `${cell.border.left.color.argb}` : "00000",
                border: cell.border,
                alignment: { wrapText: true }
            };
        })
    };

    // Identify the earliest and latest date cells
    const dateCells = item.contentCells.filter(cell => cell.type === 'date');
    if (dateCells.length > 1) {
        dateCells.sort((a, b) => new Date(a.text) - new Date(b.text));
        item.startDate = dateCells[0].text;
        item.endDate = dateCells[1].text;
    } else if (dateCells.length === 1) {
        item.startDate = dateCells[0].text;
    }

    return item;
};


// Determine content type
const determineCellType = (text, cell) => {
    // Check if the cell is a date type from Excel
    if (cell && cell.type === 'date') {
        console.log(`Date found: ${text} (Excel date type)`);
        return 'date';
    }

    // Date regex that covers various common date formats including YYYY/MM/DD, DD-MM-YYYY, and MM/DD/YYYY
    if (text.match(/^\d{4}[-\/]\d{2}[-\/]\d{2}$|^\d{2}[-\/]\d{2}[-\/]\d{4}$/)) {
        console.log(`Date found: ${text} (matched basic date regex)`);
        return 'date';
    }

    // Additional check for Excel date formats (including serial numbers)
    // Check for Excel date serial number (including fractional parts)
    if (text.match(/^\d{5,}(\.\d+)?$/)) {
        const date = new Date((parseFloat(text) - 25569) * 86400 * 1000);
        if (!isNaN(date.getTime())) {
            console.log(`Date found: ${text} (Excel serial number, converted to: ${date.toISOString()})`);
            return 'date';
        }
    }

    // Check for various date string formats
    const dateFormats = [
        'yyyy-MM-dd',
        'dd-MM-yyyy',
        'MM/dd/yyyy',
        'yyyy/MM/dd',
        'dd/MM/yyyy',
        'MMMM d, yyyy',
        'MMM d, yyyy',
        'd MMMM yyyy',
        'd MMM yyyy',
        'yyyy-MM-dd HH:mm:ss',
        'yyyy-MM-dd HH:mm',
        'dd-MM-yyyy HH:mm:ss',
        'dd-MM-yyyy HH:mm',
        'MM/dd/yyyy HH:mm:ss',
        'MM/dd/yyyy HH:mm',
    ];

    for (const dateFormat of dateFormats) {
        const parsedDate = parse(text, dateFormat, new Date());
        if (!isNaN(parsedDate.getTime())) {
            console.log(`Date found: ${text} (matched format: ${dateFormat}, parsed as: ${parsedDate.toISOString()})`);
            return 'date';
        }
    }

    // Tag regex to find patterns separated by semicolons, assuming non-empty tags
    if (text.match(/^(?:[^;]*;)+[^;]*$/)) return 'tag';

    // Emoji regex to match text that only contains emojis
    if (text.match(/^[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]+$/u)) return 'emoji';

    // ID regex to match a hash followed by one or more digits
    if (text.match(/^#\d+$/)) return 'id';

    // Assignee regex to match a colon followed by one or more Unicode letters or numbers
    if (text.match(/^:[\p{L}\p{N}]+$/u)) return 'assignee';

    // Todo regex to match "-[]" or "-[X]" across multiple lines and formats
    if (text.match(/^-?\[ ?[X ]?\] .+$/im)) return 'todo';

    // Hyperlink regex to match any web URL
    if (text.match(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/)) return 'hyperlink';

    // Default to text if no other type is matched
    return 'text';
};

// New function to convert date to MM/DD/YYYY format
export const formatDateToMMDDYYYY = (dateText) => {
    const dateFormats = [
        'yyyy-MM-dd',
        'dd-MM-yyyy',
        'MM/dd/yyyy',
        'yyyy/MM/dd',
        'dd/MM/yyyy',
        'MMMM d, yyyy',
        'MMM d, yyyy',
        'd MMMM yyyy',
        'd MMM yyyy',
        'yyyy-MM-dd HH:mm:ss',
        'yyyy-MM-dd HH:mm',
        'dd-MM-yyyy HH:mm:ss',
        'dd-MM-yyyy HH:mm',
        'MM/dd/yyyy HH:mm:ss',
        'MM/dd/yyyy HH:mm',
    ];

    for (const dateFormat of dateFormats) {
        const parsedDate = parse(dateText, dateFormat, new Date());
        if (!isNaN(parsedDate.getTime())) {
            return format(parsedDate, 'MM/dd/yyyy');
        }
    }

    // If the date couldn't be parsed, return the original text
    return dateText;
};








// ----- DRAG & DROP -----

// columnUtils.js

export const processDragResult = (columnsData, result) => {
    const { source, destination } = result;

    if (!columnsData || columnsData.length === 0) { 
        console.log('No columns data');
        return;
    }

    if (!destination) {
        console.log('No destination');
        return columnsData;  // Return original columns data if dropped outside a droppable area
    }

    const sourceIndex = source.index.toString();
    const sourceColumnIndex = source.droppableId.toString();
    const destIndex = destination.index.toString();
    const destColumnIndex = destination.droppableId.toString();
    
    // Create a new copy of columnsData for immutability
    const newColumnsData = [...columnsData];

    const sourceColumn = newColumnsData.find(col => col.index.toString() === sourceColumnIndex);
    const destColumn = newColumnsData.find(col => col.index.toString() === destColumnIndex);

    if (!sourceColumn) {
        console.error('Source column not found:', sourceColumnIndex);
        return columnsData;
    }

    if (!destColumn) {
        console.error('Destination column not found:', destColumnIndex);
        return columnsData;
    }

    if (sourceColumnIndex === destColumnIndex) {
        // Reordering within the same column
        const column = newColumnsData.find(col => col.index.toString() === sourceColumnIndex);
        const movedItem = column.items.splice(sourceIndex, 1)[0];
        column.items.splice(destIndex, 0, movedItem);
    } else {
        // Moving between different columns
        const sourceColumn = newColumnsData.find(col => col.index.toString() === sourceColumnIndex);
        const destColumn = newColumnsData.find(col => col.index.toString() === destColumnIndex);
        const movedItem = sourceColumn.items.splice(sourceIndex, 1)[0];
        destColumn.items.splice(destIndex, 0, movedItem);
    }

    return newColumnsData;
};


// export const processDragResult = (columnsData, result) => {
//     const { source, destination } = result;

//     if (!destination) {
//         return columnsData;  // Return original columns data if dropped outside a droppable area
//     }

//     const sourceIndex = source.index;
//     const sourceColumnIndex = source.droppableId;
//     const destIndex = destination.index;
//     const destColumnIndex = destination.droppableId;

//     // Create a new copy of columnsData for immutability
//     const newColumnsData = [...columnsData];

//     if (sourceColumnIndex === destColumnIndex) {
//         // Reordering within the same column
//         const column = newColumnsData.find(col => col.index.toString() === sourceColumnIndex);
//         const movedItem = column.items.splice(sourceIndex, 1)[0];
//         column.items.splice(destIndex, 0, movedItem);
//     } else {
//         // Moving between different columns
//         const sourceColumn = newColumnsData.find(col => col.index.toString() === sourceColumnIndex);
//         const destColumn = newColumnsData.find(col => col.index.toString() === destColumnIndex);
//         const movedItem = sourceColumn.items.splice(sourceIndex, 1)[0];
//         destColumn.items.splice(destIndex, 0, movedItem);
//     }

//     return newColumnsData;
// };



// ----- UPDATE WORKSHEET -----

export const updateWorksheetFromState = async (worksheet, columnsData, config) => {
    try {
        // Clear existing content first if necessary or write over existing data
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {  // Assuming row 1 is headers
                row.eachCell((cell) => {
                    cell.value = null;  // Clear cell content
                    cell.style = {};    // Reset styles
                    // Set light gray background for all cells initially
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFF0F0F0' } // Light gray background
                    };
                });
            }
        });

        // Freeze top row
        worksheet.views = [
            { state: 'frozen', ySplit: 1 }
        ];

        // Style the header row
        const headerRow = worksheet.getRow(1);
        headerRow.eachCell((cell) => {
            cell.font = { name: 'Arial', bold: true, size: 12, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF0000FF' } // Blue background
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: false };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        const fixedRowHeight = 16; // Set a fixed height for all rows

        // Now, write new data based on columnsData
        columnsData.forEach(column => {
            let rowIndex = 3;  // Start from row 3 to create a gap under headers for visual space

            // Update columns
            const col = worksheet.getColumn(column.index);
            col.width = 40;

            column.items.forEach(item => {
                
                item.contentCells.forEach((contentCell, cellIndex, cellArray) => {
                    const cell = worksheet.getCell(rowIndex + cellIndex, column.index);

                    console.log("New data", contentCell);

                    // Check if the contentCell has rich text
                    if (contentCell.richText) {
                        cell.value = { 
                            richText: contentCell.richText.map(rt => ({
                                ...rt,
                                font: { 
                                    name: 'Arial', 
                                    size: 11, 
                                    bold: cellIndex === 0, // Make first cell bold
                                    ...rt.font 
                                }
                            })) 
                        };
                    } else if (contentCell.type === 'date') {
                        // Parse the date string and set it as a JavaScript Date object
                        const parsedDate = new Date(contentCell.text);
                        if (!isNaN(parsedDate.getTime())) {
                            cell.value = parsedDate;
                            cell.numFmt = 'mm/dd/yyyy';  // Set number format for date
                        } else {
                            cell.value = contentCell.text;  // Fallback to text if parsing fails
                        }
                        cell.font = { 
                            name: 'Arial', 
                            size: 11,
                        };
                    }
                    else {
                        cell.value = contentCell.text;
                        // Apply common font settings
                        cell.font = { 
                            name: 'Arial', 
                            size: 11,
                            bold: cellIndex === 0 // Make first cell bold
                        };
                    }

                    // Set background color to white for cells with content
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFFFFF' } // White background for cells with content
                    };

                    // Apply border style from config
                    cell.border = {
                        left: { style: "medium", color: { argb: item.leftBorderColor ? item.leftBorderColor : "000000" } },
                        right: { style: config.borderStyle.style, color: { argb: config.borderStyle.color.argb } },
                    };

                    // Add top border only for the first cell of the item
                    if (cellIndex === 0) {
                        cell.border.top = { style: config.borderStyle.style, color: { argb: config.borderStyle.color.argb } };
                    }

                    // Add bottom border only for the last cell of the item
                    if (cellIndex === cellArray.length - 1) {
                        cell.border.bottom = { style: config.borderStyle.style, color: { argb: config.borderStyle.color.argb } };
                    }

                    // Allow text to wrap within the cell, but maintain a fixed row height
                    cell.alignment = { 
                        vertical: 'top', 
                        wrapText: true,
                        shrinkToFit: false
                    };

                    // Set fixed row height
                    worksheet.getRow(rowIndex + cellIndex).height = fixedRowHeight;
                });

                rowIndex += item.contentCells.length + config.margin;  // Apply margin
            });
        });

        // Ensure there's an empty column after each column with content
        columnsData.forEach(column => {
            const nextColumnIndex = column.index + 1;
            const headerCell = worksheet.getRow(1).getCell(nextColumnIndex);
            if (headerCell.value) {
                headerCell.value = null;
                headerCell.style = {};
            }
        });

        // Uncomment the following line to save the workbook if this script runs in a Node.js environment
        // await worksheet.workbook.xlsx.writeFile('path_to_your_file.xlsx');  // Save changes
    } catch (error) {
        console.error('Failed to update workbook:', error);
    }
};

export const getAllUniqueTags = (columnsData) => {
    const allTags = new Set();
    columnsData.forEach(column => {
        column.items.forEach(item => {
            item.contentCells.forEach(cell => {
                if (cell.type === 'tag' && cell.text) {
                    const tags = cell.text.split(';').map(tag => tag.trim());
                    tags.forEach(tag => {
                        if (tag) allTags.add(tag);
                    });
                }
            });
        });
    });
    return Array.from(allTags);
};

export const getAllUniqueAssignees = (columnsData) => {
    const allAssignees = new Set();
    columnsData.forEach(column => {
        column.items.forEach(item => {
            item.contentCells.forEach(cell => {
                if (cell.type === 'assignee' && cell.text) {
                    const assignee = cell.text.replace(/^:/, '').trim(); // Remove leading colon and trim
                    if (assignee) allAssignees.add(assignee);
                }
            });
        });
    });
    return Array.from(allAssignees);
};