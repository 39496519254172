// CONVERT RICH TEXT TO HTML
export function richTextToHtml(richText) {
    return richText.map(fragment => {
        let text = fragment.text;
        const { font } = fragment;

        // Convert text formatting
        if (font) {
            if (font.bold) {
                text = `<b>${text}</b>`;
            }
            if (font.italic) {
                text = `<i>${text}</i>`;
            }
            if (font.underline) {
                text = `<u>${text}</u>`;
            }
        }

        return text.replace(/\n/g, '<br>');
    }).join('');
}

// CONVERT HTML TO RICH TEXT BEFORE SAVING
export function parseRichTextForExcel(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const richText = [];

    const processNode = (node) => {
        const styles = {};
        let textContent = node.textContent;
        let currentNode = node;

        while (currentNode.parentNode && currentNode.parentNode.tagName !== 'BODY') {
            const parent = currentNode.parentNode;
            if (parent.tagName === 'B' || parent.tagName === 'STRONG') {
                styles.bold = true;
            }
            if (parent.tagName === 'I' || parent.tagName === 'EM') {
                styles.italic = true;
            }
            if (parent.tagName === 'U') {
                styles.underline = { style: 'single' };
            }
            currentNode = parent;
        }

        if (node.nodeName === 'BR' || node.nodeName === 'DIV' ) {
            textContent = '\n';
        }

        return { text: textContent, font: styles };
    };

    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, null, false);
    let node;
    while ((node = walker.nextNode())) {
        richText.push(processNode(node));
    }

    return richText.filter(part => part.text.trim().length > 0 || part.text.includes('\n'));
}
