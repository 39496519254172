import React from 'react';

const CardText = ({ cellContent }) => {
    // Function to parse text from the cell content
    const parseText = (content) => {
        if (!content) return null;

        // Check if the content is rich text or HTML
        if (content.match(/<\/?[a-z][\s\S]*>/i)) {
            // If rich text or HTML, return "richText" to indicate special formatting
            return "richText";
        }

        // If no rich text, then return the actual text
        return content;
    };

    const text = parseText(cellContent);

    return (
        <span className="pt-0.5 text-xs font-normal text-zinc-500 line-clamp-1 leading-snug text-pretty">
            {text === "richText" ? (
                <div className='py-1'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-3 h-3">
                        <path fillRule="evenodd" d="M2 3.75A.75.75 0 0 1 2.75 3h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 8Zm0 4.25a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                    </svg>
                </div>
            ) : (
                // Display normal text without an icon
                <span>{text}</span>
            )}
        </span>
    );
};

export default CardText;
