import React, { useState, useEffect, useRef } from 'react';
import { TagIcon } from 'lucide-react';

const Tags = ({ cellContent, onChange, editable, uniqueTags = [] }) => {
    const parseTags = (content) => {
        if (!content) return [];
        return content.split(';').filter(tag => tag.trim() !== '').map(tag => tag.trim());
    };

    const [tags, setTags] = useState(parseTags(cellContent));
    const [inputValue, setInputValue] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const componentRef = useRef(null);

    useEffect(() => {
        setTags(parseTags(cellContent));
    }, [cellContent]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleAddTag = (newTag) => {
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        setInputValue('');
        setShowSuggestions(false);
        setSelectedSuggestionIndex(-1);
        updateParent(updatedTags);
    };

    const handleDeleteTag = (index) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        setTags(updatedTags);
        updateParent(updatedTags);
    };

    const handleKeyDown = (e) => {
        const suggestions = getSuggestions();
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedSuggestionIndex(prev => (prev < suggestions.length - 1 ? prev + 1 : prev));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedSuggestionIndex(prev => (prev > 0 ? prev - 1 : prev));
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (selectedSuggestionIndex >= 0 && selectedSuggestionIndex < suggestions.length) {
                handleAddTag(suggestions[selectedSuggestionIndex]);
            } else if (inputValue.trim()) {
                handleAddTag(inputValue.trim());
            }
        } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
            const newTags = tags.slice(0, -1);
            setTags(newTags);
            updateParent(newTags);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        setInputValue(value);
        setShowSuggestions(value.length > 0);
        setSelectedSuggestionIndex(-1);
    };

    const updateParent = (updatedTags) => {
        const filteredTags = updatedTags.filter(tag => tag !== '');
        onChange(filteredTags.length > 0 ? filteredTags.map(tag => `;${tag}`).join(' ') : null);
    };

    const getSuggestions = () => {
        if (!Array.isArray(uniqueTags)) return [];
        const lowercaseTags = tags.map(tag => tag.toLowerCase());
        return uniqueTags.filter(tag => 
            tag.toLowerCase().includes(inputValue.toLowerCase()) && 
            !lowercaseTags.includes(tag.toLowerCase())
        );
    };

    if (!editable && (!tags || tags.length === 0)) {
        return null;
    }

    const suggestions = getSuggestions();

    return (
        <div 
            ref={componentRef} 
            className={`relative flex flex-wrap items-center gap-1 w-full ${editable ? 'p-1 border border-zinc-200 rounded-md' : ''}`}
        >
            {tags.map((tag, index) => (
                <div key={index} className={`inline-flex items-center gap-x-[1px] text-xs text-zinc-500 ${editable ? 'rounded-full px-2.5 py-1 font-medium bg-zinc-100' : ''}`}>
                    <TagIcon className="w-3 h-3 stroke-zinc-500" />
                    <span>{tag}</span>
                    {editable && (
                        <button
                            onClick={() => handleDeleteTag(index)}
                            className="text-zinc-400 hover:text-zinc-600 ml-0.5 transition-colors duration-200"
                            aria-label="Remove tag"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                            </svg>
                        </button>
                    )}
                </div>
            ))}
            {editable && (
                <div className="flex-grow">
                    <input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        onFocus={() => setShowSuggestions(true)}
                        className="bg-transparent focus:outline-none px-1.5 py-1.5 text-sm w-full"
                        placeholder={tags.length === 0 ? "Type a tag..." : "Add a tag..."}
                    />
                    {showSuggestions && (
                        <div
                            ref={dropdownRef}
                            className="absolute left-0 right-0 mt-1 bg-white border border-zinc-200 rounded-md shadow-lg text-sm z-10 max-h-48 overflow-y-auto transition-all duration-300 ease-in-out transform origin-top"
                        >
                            {suggestions.length > 0 ? (
                                suggestions.map((tag, index) => (
                                    <div
                                        key={index}
                                        className={`p-1.5 cursor-pointer transition-colors duration-200 ${
                                            index === selectedSuggestionIndex ? 'bg-zinc-100' : 'hover:bg-zinc-50'
                                        }`}
                                        onClick={() => handleAddTag(tag)}
                                    >
                                        {tag}
                                    </div>
                                ))
                            ) : (
                                <div className="p-2 text-zinc-500 italic">
                                    Hit enter to create new tag
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Tags;
