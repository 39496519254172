import React, { useEffect, useRef, forwardRef } from 'react';

const RichTextEditor = forwardRef(({ content, onChange }, ref) => {
    const textRef = useRef(null);

    useEffect(() => {
        if (textRef.current && textRef.current.innerHTML !== content) {
            textRef.current.innerHTML = content;
        }
    }, [content]);

    const handleInput = () => {
        if (textRef.current.innerHTML !== content) {
            onChange(textRef.current.innerHTML);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const text = (e.clipboardData || window.clipboardData).getData('text');
        document.execCommand('insertText', false, text);
        handleInput();
    };

    const handleFocus = () => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(textRef.current);
        range.collapse(false); // false means collapse to end
        selection.removeAllRanges();
        selection.addRange(range);
    };

    return (
        <div className="editor pt-4">
            <p className='block text-sm font-medium leading-6 text-zinc-900'>Description</p>
            <div
                ref={(node) => {
                    textRef.current = node;
                    if (typeof ref === 'function') ref(node);
                    else if (ref) ref.current = node;
                }}
                contentEditable
                className="min-h-[150px] max-h-[300px] overflow-y-auto ring-1 ring-inset ring-zinc-200 rounded-md px-2 py-1 mt-1 text-zinc-900 placeholder:text-zinc-400 focus:ring-0 sm:text-sm sm:leading-6"
                onInput={handleInput}
                onPaste={handlePaste}
                onFocus={handleFocus}
                placeholder="Type something..."
            />
        </div>
    );
});

export default RichTextEditor;