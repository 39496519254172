import React, { useCallback, useState } from 'react';
import * as ExcelJS from 'exceljs';
import { useFileSystem } from '../Configs/FileSystemContext';
import { useDropzone } from 'react-dropzone';

const NewWorkbook = ({ onNewWorkbook }) => {
    const { openFile } = useFileSystem();
    const [isLoading, setIsLoading] = useState(false);

    const handleCreateNewWorkbook = () => {
        console.log('Creating new workbook');
        const newWorkbook = new ExcelJS.Workbook();
        newWorkbook.addWorksheet('Sheet 1');
        console.log('New workbook created');
        onNewWorkbook(newWorkbook);
    };

    const processFile = async (file, handle = null) => {
        console.log('Starting file processing', { fileName: file.name, fileSize: file.size });
        const newWorkbook = new ExcelJS.Workbook();
        console.log('Loading file into workbook');
        try {
            await newWorkbook.xlsx.load(await file.arrayBuffer());
            console.log('File loaded successfully');
            newWorkbook.fileInfo = {
                fileName: file.name,
                handle: handle
            };
            console.log('Workbook processed, calling onNewWorkbook');
            onNewWorkbook(newWorkbook);
        } catch (error) {
            console.error('Error processing file:', error);
        }
    };

    const handleFileOpen = async () => {
        console.log('Starting handleFileOpen');
        setIsLoading(true);
        try {
            console.log('Calling openFile function');
            const result = await openFile();
            console.log('openFile function completed', { resultType: result ? typeof result : 'null' });

            if (result) {
                console.log('File dialog result: File selected');
                const { file, handle } = result;
                console.log('Processing file', { fileName: file.name, fileSize: file.size });
                await processFile(file, handle);
            } else {
                console.log('File dialog result: No file selected');
            }
        } catch (error) {
            console.error('Error in handleFileOpen:', error);
            // Handle specific errors
            if (error.message === 'Read permission denied') {
                alert('Permission to read the file was denied. Please try again and grant permission.');
            } else if (error.message === 'No file selected') {
                alert('No file was selected. Please choose a file.');
            } else {
                alert('An error occurred while opening the file. Please try again.');
            }
        } finally {
            console.log('Setting isLoading to false');
            setIsLoading(false);
        }
        console.log('handleFileOpen completed');
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        console.log('File dropped', { filesCount: acceptedFiles.length });
        if (acceptedFiles && acceptedFiles.length > 0) {
            setIsLoading(true);
            try {
                await processFile(acceptedFiles[0]);
            } catch (error) {
                console.error('Error processing dropped file:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        multiple: false,
        noClick: true,
        noKeyboard: true
    });

    console.log('Rendering NewWorkbook component');

    return (
        <div className="flex flex-col items-center justify-center h-full p-4">
            <button
                onClick={handleCreateNewWorkbook}
                className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
                Create New Workbook
            </button>
            <div className="w-full max-w-md">
                <div
                    {...getRootProps()}
                    className={`mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 ${
                        isDragActive ? 'bg-blue-50' : ''
                    }`}
                >
                    <div className="text-center">
                        <div className="flex text-sm leading-6 text-gray-600">
                            <button
                                onClick={handleFileOpen}
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                                Upload a file
                            </button>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">XLSX up to 10MB</p>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="mt-4 text-center text-gray-600">
                    Loading file...
                </div>
            )}
        </div>
    );
};

export default NewWorkbook;