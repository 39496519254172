import React, { useState, useRef, Fragment, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { findFirstCellOfType, findNthCellOfType, getCellText } from './itemsUtils';  // Import utilities
import { parseRichTextForExcel } from '../Utilities/RichTextUtils';

import { Dialog, Transition } from '@headlessui/react';
import ColorPicker from './colorPicker';
import Tags from './tags';
import Assignees from './assignees';
import Dates from './dates';
import Todos from './todos';
import VisualId from './visualId';
import RichTextEditor from './richTextEditor';
import Hyperlink from './hyperlink';

const NewItemModal = ({ isOpen, onClose, onSave, item, columnIndex, onDelete, uniqueTags, uniqueAssignees }) => {
    const [additionalFields, setAdditionalFields] = useState([]);
    const [itemData, setItemData] = useState({
        id: '',
        title: '',
        description: '',
        tags: '',
        todo: '',
        visual_id: '',
        date: '',
        startDate: '',
        endDate: '',
        assignee: '',
        emoji: '',
        leftBorderColor: '',
        additionalFields: [],
        hyperlink: '',
    });
    const itemDataRef = useRef(itemData); // Use ref to store latest state
    const cancelButtonRef = useRef(null);
    const titleInputRef = useRef(null);
    const saveButtonRef = useRef(null);
    const descriptionInputRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            setItemData(prev => ({
                ...prev,
                ...item ? {
                    id: item.id,
                    title: getCellText(findNthCellOfType(item.contentCells, 'text', 1)),
                    description: getCellText(findNthCellOfType(item.contentCells, 'text', 2)),
                    tags: getCellText(findFirstCellOfType(item.contentCells, 'tag')),
                    hyperlink: getCellText(findFirstCellOfType(item.contentCells, 'hyperlink')),
                    todo: getCellText(findFirstCellOfType(item.contentCells, 'todo')),
                    visual_id: getCellText(findFirstCellOfType(item.contentCells, 'id')),
                    startDate: item.startDate,
                    endDate: item.endDate,
                    assignee: getCellText(findFirstCellOfType(item.contentCells, 'assignee')),
                    emoji: getCellText(findFirstCellOfType(item.contentCells, 'emoji')),
                    leftBorderColor: item.leftBorderColor,
                    contentCells: item.contentCells,
                    additionalFields: item.contentCells.filter(cell => !['text', 'tag', 'todo', 'id', 'date', 'assignee', 'emoji', 'hyperlink', 'startDate', 'endDate'].includes(cell.type)),
                } : {
                    id: '',
                    title: '',
                    description: '',
                    tags: '',
                    todo: '',
                    visual_id: '',
                    date: '',
                    startDate: '',
                    endDate: '',
                    assignee: '',
                    emoji: '',
                    leftBorderColor: '',
                    contentCells: [], 
                    additionalFields: [],
                    hyperlink: ''
                }
            }));

            console.log(item);

            const handleKeyDown = (event) => {
                if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                    event.preventDefault();
                    handleSave();
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [isOpen, item]);

    useEffect(() => {
        itemDataRef.current = itemData; // Synchronize ref with state
    }, [itemData]);

    const parseDescriptionForExcel = (description) => {
        return description ? parseRichTextForExcel(description) : null;
    };

    const prepareTodos = (todo) => {
        return todo ? formatTodosForSaving(todo) : null;
    };

    const handleSave = () => {
        const currentItemData = itemDataRef.current;

        const richTextDescription = parseDescriptionForExcel(currentItemData.description);
        const todoContent = prepareTodos(currentItemData.todo);

        // Initialize contentCells if it doesn't exist (for new items)
        if (!currentItemData.contentCells) {
            currentItemData.contentCells = [];
        }

        // Update or create tag cell
        let tagCell = currentItemData.contentCells.find(cell => cell.type === 'tag');
        if (currentItemData.tags) {
            if (tagCell) {
                tagCell.text = currentItemData.tags;
            } else {
                tagCell = { text: currentItemData.tags, type: 'tag' };
                currentItemData.contentCells.push(tagCell);
            }
        } else if (tagCell) {
            // Remove tag cell if tags are empty
            currentItemData.contentCells = currentItemData.contentCells.filter(cell => cell.type !== 'tag');
        }

        // Remove existing date cells
        currentItemData.contentCells = currentItemData.contentCells.filter(cell => cell.type !== 'date');

        // Add date cells
        const dateCells = [];
        if (currentItemData.startDate) {
            dateCells.push({ 
                text: currentItemData.startDate, 
                type: 'date', 
                startDate: currentItemData.startDate 
            });
        }
        if (currentItemData.endDate) {
            dateCells.push({ 
                text: currentItemData.endDate, 
                type: 'date', 
                endDate: currentItemData.endDate 
            });
        }

        const contentCells = [
            { text: currentItemData.title, type: 'text' },
            richTextDescription && { richText: richTextDescription, type: 'text' },
            tagCell && { text: currentItemData.tags, type: 'tag' },
            todoContent && { text: todoContent, type: 'todo' },
            currentItemData.hyperlink && { text: currentItemData.hyperlink, type: 'hyperlink' },
            currentItemData.assignee && { text: currentItemData.assignee, type: 'assignee' },
            ...dateCells,
            ...currentItemData.contentCells.filter(cell => !['text', 'tag', 'todo', 'id', 'date', 'assignee', 'emoji', 'hyperlink'].includes(cell.type))
        ].filter(Boolean);

        const itemToSave = {
            id: currentItemData.id || uuidv4(),
            state: columnIndex,
            leftBorderColor: currentItemData.leftBorderColor,
            contentCells
        };

        console.log('Item to save:', itemToSave);
        onSave(itemToSave);
        onClose();
    };

    function formatTodosForSaving(todoText) {
        return todoText.split('\n').map(todo => {
            const checked = todo.includes('[X]');
            const text = todo.replace(/-\[(X|\s)\]\s/, '');
            return `-[${checked ? 'X' : ' '}] ${text}`;
        }).join('\n');
    }

    const handleTodoChange = (newTodos) => {
        setItemData(prev => ({
            ...prev,
            todo: newTodos
        }));
    };

    const handleAddField = (type) => {
        setAdditionalFields([...additionalFields, { text: '', type: type }]);
    };

    const handleFormChange = (field) => (valueOrEvent) => {
        const value = valueOrEvent.target ? valueOrEvent.target.value : valueOrEvent;
        setItemData(prev => ({ ...prev, [field]: value }));
    };

    const handleAdditionalFieldChange = (text, index) => {
        const updatedFields = [...additionalFields];
        updatedFields[index].text = text;
        setAdditionalFields(updatedFields);
    };

    const handleAdditionalFieldDelete = (index) => {
        const updatedFields = additionalFields.filter((_, idx) => idx !== index);
        setAdditionalFields(updatedFields);
    };

    const isSaveEnabled = itemData.title.trim() !== '';

    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            onDelete(item.id);
            onClose();
        }
    };

    const handleTagChange = (newTags) => {
        setItemData(prev => ({
            ...prev,
            tags: newTags,
            contentCells: prev.contentCells.map(cell => 
                cell.type === 'tag' ? { ...cell, text: newTags } : cell
            )
        }));
    };

    const handleAssigneeChange = (newAssignee) => {
        setItemData(prev => ({
            ...prev,
            assignee: newAssignee,
            contentCells: prev.contentCells.map(cell => 
                cell.type === 'assignee' ? { ...cell, text: newAssignee } : cell
            )
        }));
    };

    const handleTitleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            descriptionInputRef.current?.focus();
        }
    };

    const handleHyperlinkChange = (newUrl) => {
        setItemData(prev => ({ ...prev, hyperlink: newUrl }));
    };

    const handleDateChange = (newDate, dateType) => {
        console.log(`Date change: ${dateType} set to ${newDate}`);
        setItemData(prev => {
            const updatedData = {
                ...prev,
                [dateType]: newDate,
                contentCells: prev.contentCells.some(cell => cell.type === 'date')
                    ? prev.contentCells.map(cell => 
                        cell.type === 'date' ? { ...cell, text: newDate, [dateType]: newDate } : cell
                    )
                    : [...prev.contentCells, { text: newDate, type: 'date', [dateType]: newDate }]
            };
            console.log('Handle date change:', updatedData);
            return updatedData;
        });
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={titleInputRef} onClose={() => onClose()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-zinc-400 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-1/2">
                                <div className="flex flex-col bg-white px-4 pt-4">
                                    <div className='grow'>
                                        <div className='flex flex-inline space-x-2 align-middle'>
                                            <input
                                                type="text"
                                                value={itemData.title}
                                                onChange={handleFormChange('title')}
                                                onKeyDown={handleTitleKeyDown}
                                                className="block w-full border-0 text-lg font-medium placeholder:text-zinc-400 focus:ring-0 focus:outline-none"
                                                placeholder="Title"
                                                ref={titleInputRef}
                                            />
                                        </div>

                                        <RichTextEditor
                                            content={itemData.description}
                                            onChange={handleFormChange('description')}
                                            ref={descriptionInputRef}
                                        />

                                        <div className="flex space-x-1.5">
                                            <Dates 
                                                startCellContent={itemData.startDate}
                                                endCellContent={itemData.endDate}
                                                editable={true}
                                                onStartChange={(newStartDate) => handleDateChange(newStartDate, 'startDate')}
                                                onEndChange={(newEndDate) => handleDateChange(newEndDate, 'endDate')}
                                            />
                                        </div>

                                        <div className='pt-4'>
                                            <p className='pb-2 block text-sm font-medium leading-6 text-zinc-900'>Tags</p>
                                            <Tags cellContent={itemData.tags} onChange={handleTagChange} editable={true} uniqueTags={uniqueTags} />
                                        </div>

                                        <div className='pt-4'>
                                            <p className='pb-2 block text-sm font-medium leading-6 text-zinc-900'>Assignee</p>
                                            <Assignees 
                                                cellContent={itemData.assignee} 
                                                onChange={handleAssigneeChange} 
                                                editable={true} 
                                                uniqueAssignees={uniqueAssignees} 
                                            />
                                        </div>

                                        <Hyperlink 
                                            url={itemData.hyperlink}
                                            text={itemData.hyperlink}
                                            editable={true}
                                            onChange={handleHyperlinkChange}
                                        />


                                        <div className='pt-4'>
                                            <p className='pb-2 block text-sm font-medium leading-6 text-zinc-900'>Color</p>
                                            <ColorPicker onChange={handleFormChange('leftBorderColor')} format="ARGB" defaultColor={itemData.leftBorderColor} variant="inline" />
                                        </div>
                                        {/* <div className='pt-4'>
                                            <p className='block text-sm font-medium leading-6 text-zinc-900'>Todos</p>
                                            <Todos
                                                cellContent={itemData.todo}
                                                editable={true}
                                                onChange={handleTodoChange}
                                            />
                                        </div> */}
                                    </div>
                                    {itemData.id && (
                                        <div className="flex">
                                            <VisualId cellContent={itemData.visual_id} />
                                        </div>
                                    )}
                                    

                                    <div className="flex py-3 space-x-2">
                                        {item && (
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:ring-red-600"
                                                onClick={handleDelete}
                                            >
                                                Delete
                                            </button>
                                        )}
                                        <div className='grow'></div>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 disabled:bg-zinc-300 focus:ring-green-600"
                                            onClick={handleSave}
                                            disabled={!isSaveEnabled}
                                            ref={saveButtonRef}
                                        >
                                            Save (Cmd/Ctrl + Enter)
                                        </button>
                                        <button
                                            type="button"
                                            className="ml-3 inline-flex justify-center rounded-md border border-zinc-200 bg-white px-4 py-2 text-sm font-medium text-zinc-700 shadow-sm hover:bg-zinc-50 focus:ring-green-600"
                                            onClick={() => onClose()}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default NewItemModal;