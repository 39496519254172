import React, { createContext, useContext, useState, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

const DragDropContextProviderContext = createContext();

export const useDragDropContext = () => useContext(DragDropContextProviderContext);

const DragDropContextProvider = ({ children }) => {
    const [dragEndHandlers, setDragEndHandlers] = useState([]);

    const onDragEnd = useCallback((result) => {
        dragEndHandlers.forEach(handler => handler(result));
    }, [dragEndHandlers]);

    const registerDragEndHandler = useCallback((handler) => {
        setDragEndHandlers(prevHandlers => [...prevHandlers, handler]);
        return () => {
            setDragEndHandlers(prevHandlers => prevHandlers.filter(h => h !== handler));
        };
    }, []);

    const value = {
        onDragEnd,
        registerDragEndHandler
    };

    return (
        <DragDropContextProviderContext.Provider value={value}>
            <DragDropContext onDragEnd={onDragEnd}>
                {children}
            </DragDropContext>
        </DragDropContextProviderContext.Provider>
    );
};

export default DragDropContextProvider;
