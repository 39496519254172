import React, { useState, useEffect, useRef } from 'react';
import { Link2Icon } from 'lucide-react';

const Hyperlink = ({ url, text, editable, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(url || '');
  }, [url]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    }
  };

  const handleSave = () => {
    onChange(inputValue.trim());
  };

  if (!editable && !url) {
    return null;
  }

  return (
    <div className="flex items-center justify-center">
      {editable ? (
        <div className="w-full pt-4">
          <label htmlFor="hyperlink" className="block pb-2 text-sm font-medium leading-6 text-zinc-900">
            Hyperlink
          </label>
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleSave}
            className="block w-full rounded-md border-0 p-2 px-2.5 text-zinc-900 ring-1 ring-inset ring-zinc-200 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            placeholder="Enter URL"
          />
        </div>
      ) : (
        <span className='flex items-center justify-center gap-x-[1px] text-xs text-zinc-500'><Link2Icon className="w-3 h-3 stroke-zinc-500" />1</span>
      )}
    </div>
  );
};

export default Hyperlink;