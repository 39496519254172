import React from 'react';
import { FileSystemProvider } from './Configs/FileSystemContext';
import { ItemConfigProvider } from './Configs/ItemConfigContext';
import DragDropContextProvider from './Configs/DragDropContextProvider';
import WorkbookManager from './Workbook/WorkbookManager';

const App = () => {
    return (
        <FileSystemProvider>
        <ItemConfigProvider>
            <DragDropContextProvider>
                <WorkbookManager />
            </DragDropContextProvider>
        </ItemConfigProvider>
        </FileSystemProvider>
    );
};

export default App;