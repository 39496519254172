// FileSystemContext.js
import React, { createContext, useState, useContext, useCallback } from 'react';

const FileSystemContext = createContext();

const isFileSystemAccessSupported = () => 'showOpenFilePicker' in window && 'showSaveFilePicker' in window;

// Global permission cache
const globalPermissionCache = {};

export const FileSystemProvider = ({ children }) => {
    const [fileHandles, setFileHandles] = useState({});
    const [isSaved, setIsSaved] = useState(true);

    const downloadWorkbook = useCallback(async (workbook, fileName) => {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
        setIsSaved(true);
    }, []);

    const verifyPermission = useCallback(async (fileHandle, readWrite) => {
        console.log('Starting verifyPermission', { fileName: fileHandle.name, readWrite });
        const cacheKey = fileHandle.name + (readWrite ? '_write' : '_read');
        if (globalPermissionCache[cacheKey]) {
            console.log('Permission found in cache', { cacheKey });
            return true;
        }

        const options = readWrite ? { mode: 'readwrite' } : {};
        console.log('Permission options', options);
        
        try {
            console.log('Querying permission');
            const permission = await fileHandle.queryPermission(options);
            console.log('Query permission result', { permission });
            if (permission === 'granted') {
                console.log('Permission already granted');
                globalPermissionCache[cacheKey] = true;
                return true;
            }

            console.log('Requesting permission');
            const permissionPromise = fileHandle.requestPermission(options);
            const timeoutPromise = new Promise((_, reject) => 
                setTimeout(() => reject(new Error('Permission request timed out')), 5000)
            );

            const requestedPermission = await Promise.race([permissionPromise, timeoutPromise]);
            console.log('Request permission result', { requestedPermission });
            if (requestedPermission === 'granted') {
                console.log('Permission granted after request');
                globalPermissionCache[cacheKey] = true;
                return true;
            }
        } catch (error) {
            console.warn('Permission verification failed:', error);
            console.error('Detailed error:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
            if (error.message === 'Permission request timed out') {
                console.log('Permission prompt may not have been shown. Falling back to alternative method.');
                return await fallbackPermissionMethod(fileHandle, readWrite);
            }
        }

        console.log('Permission not granted');
        return false;
    }, []);

    // Add this new function
    const fallbackPermissionMethod = async (fileHandle, readWrite) => {
        const userConfirmed = await showCustomPermissionDialog(fileHandle.name, readWrite);
        if (userConfirmed) {
            try {
                // Instead of trying to perform the operation directly,
                // we'll ask the user to try again, which should trigger a user activation
                alert('Please try the operation again. This time, a permission prompt should appear.');
                return false; // Return false to indicate that the operation should be retried
            } catch (error) {
                console.error('Fallback permission method failed:', error);
                return false;
            }
        }
        return false;
    };

    // Implement this function to show a custom dialog to the user
    const showCustomPermissionDialog = async (fileName, readWrite) => {
        return window.confirm(`The browser couldn't show a permission prompt for the file "${fileName}". Would you like to try again?`);
    };

    const openFile = async () => {
        console.log('Starting openFile function');
        try {
            if (isFileSystemAccessSupported()) {
                console.log('Using showOpenFilePicker API');
                try {
                    const [fileHandle] = await window.showOpenFilePicker({
                        types: [
                            {
                                description: 'Excel Files',
                                accept: {
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                },
                            },
                        ],
                        multiple: false,
                    });
                    console.log('File picked successfully');
                    
                    const hasPermission = await verifyPermission(fileHandle, false);
                    if (!hasPermission) {
                        throw new Error('Read permission denied');
                    }

                    const file = await fileHandle.getFile();
                    console.log('File verified', { name: file.name, size: file.size });
                    return { file, handle: fileHandle };
                } catch (error) {
                    console.error('Error with showOpenFilePicker:', error);
                    throw error;
                }
            } else {
                console.log('Falling back to traditional file input');
                return new Promise((resolve, reject) => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.xlsx';
                    input.onchange = (event) => {
                        if (event.target.files && event.target.files[0]) {
                            console.log('File selected via input element');
                            resolve({ file: event.target.files[0], handle: null });
                        } else {
                            reject(new Error('No file selected'));
                        }
                    };
                    input.onerror = (error) => {
                        reject(new Error('Error in file input: ' + error));
                    };
                    input.click();
                });
            }
        } catch (error) {
            console.error('Error in openFile function:', error);
            throw error;
        }
    };

    const saveWorkbook = useCallback(async (workbook, fileName, workbookId) => {
        if (isFileSystemAccessSupported()) {
            let handle = workbook.fileHandle || fileHandles[workbookId];
            let useFileSystemAPI = true;
            
            if (!handle) {
                try {
                    handle = await window.showSaveFilePicker({
                        suggestedName: fileName,
                        types: [{
                            description: 'Excel Files',
                            accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
                        }],
                    });
                    setFileHandles(prev => ({ ...prev, [workbookId]: handle }));
                } catch (error) {
                    console.error('Error showing save file picker:', error);
                    useFileSystemAPI = false;
                }
            }

            if (useFileSystemAPI) {
                let retries = 0;
                while (retries < 2) {
                    try {
                        const hasPermission = await verifyPermission(handle, true);
                        if (!hasPermission) {
                            if (retries === 1) {
                                console.log('Permission denied after retry, falling back to download method');
                                break;
                            }
                            retries++;
                            continue;
                        }
                        
                        const writable = await handle.createWritable();
                        await workbook.xlsx.write(writable);
                        await writable.close();
                        setIsSaved(true);
                        console.log('File saved successfully using File System Access API');
                        return;
                    } catch (error) {
                        console.error('Error writing to file:', error);
                        if (retries === 1) {
                            console.log('Error persists after retry, falling back to download method');
                            break;
                        }
                        retries++;
                    }
                }
            }
        }

        // Fallback to download method
        console.log('Using fallback download method');
        await downloadWorkbook(workbook, fileName);
    }, [fileHandles, verifyPermission, downloadWorkbook]);

    const markUnsaved = useCallback((value = false) => {
        setIsSaved(value);
    }, []);

    const contextValue = {
        fileHandles,
        isSaved,
        openFile,
        saveWorkbook,
        markUnsaved,
        setFileHandles,
        verifyPermission,
        isFileSystemAccessSupported
    };

    return (
        <FileSystemContext.Provider value={contextValue}>
            {children}
        </FileSystemContext.Provider>
    );
};

export const useFileSystem = () => {
    const context = useContext(FileSystemContext);
    if (context === undefined) {
        throw new Error('useFileSystem must be used within a FileSystemProvider');
    }
    return context;
};