import React, { useState, useEffect, useCallback, useRef } from 'react';
import WorkbookTabs from '../Workbook/workbookTabs';
import Workbook from './workbook';
import NewWorkbook from '../Workbook/newWorkbook';
import { useDragDropContext } from '../Configs/DragDropContextProvider';
import { v4 as uuidv4 } from 'uuid';
import { useFileSystem } from '../Configs/FileSystemContext';
import * as ExcelJS from 'exceljs';

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.id = uuidv4();
    workbook.addWorksheet('Sheet 1');
    return workbook;
};

const forceConvertToWorkbook = (workbook) => {
    console.log('Starting forceConvertToWorkbook');
    try {
        if (workbook instanceof ExcelJS.Workbook) {
            console.log('Workbook is already an ExcelJS.Workbook instance');
            return workbook;
        }
        console.log('Converting workbook to ExcelJS.Workbook');
        const newWorkbook = new ExcelJS.Workbook();
        Object.assign(newWorkbook, workbook);
        console.log('Workbook conversion completed');
        return newWorkbook;
    } catch (error) {
        console.error('Error in forceConvertToWorkbook:', error);
        return null;
    }
};

const WorkbookManager = () => {
    const { 
        isSaved, 
        openFile, 
        saveWorkbook, 
        markUnsaved, 
        setFileHandles: setContextFileHandles, 
        verifyPermission, 
        isFileSystemAccessSupported, 
        globalEditPermission, 
        checkGlobalEditPermission 
    } = useFileSystem();

    const { registerDragEndHandler } = useDragDropContext();

    const [workbooks, setWorkbooks] = useState([]);
    const [selectedWorkbookIndex, setSelectedWorkbookIndex] = useState(-1);
    const [hasEditPermission, setHasEditPermission] = useState(false);
    const [view, setView] = useState('columns');
    const workbooksRef = useRef([]);
    const [sheets, setSheets] = useState([]);
    const [sheetTypes, setSheetTypes] = useState([]);
    const [isCheckingPermission, setIsCheckingPermission] = useState(false);
    const [localFileHandles, setLocalFileHandles] = useState({});
    const [lastCheckedWorkbookIndex, setLastCheckedWorkbookIndex] = useState(-1);

    const onDragEnd = useCallback((result) => {
        const { source, destination, type } = result;
        if (type !== 'TABS' || !destination) return;

        const reorderedWorkbooks = Array.from(workbooks);
        const [removed] = reorderedWorkbooks.splice(source.index, 1);
        reorderedWorkbooks.splice(destination.index, 0, removed);

        setWorkbooks(reorderedWorkbooks);
        setSelectedWorkbookIndex(destination.index);
    }, [workbooks]);

    useEffect(() => {
        const deregisterDragEndHandler = registerDragEndHandler(onDragEnd);
        return () => {
            deregisterDragEndHandler();
        };
    }, [registerDragEndHandler, onDragEnd]);

    const handleNewWorkbook = useCallback(async (newWorkbook, workbookId = uuidv4()) => {
        console.log('Starting handleNewWorkbook');
        try {
            const fileName = newWorkbook.fileInfo ? newWorkbook.fileInfo.fileName : `Workbook ${workbooks.length + 1}`;
            console.log('File name:', fileName);
            newWorkbook.name = fileName;
            newWorkbook.id = workbookId;
            newWorkbook.selectedSheetIndex = 0;
        
            console.log('Initial Workbook State:', {
                name: newWorkbook.name,
                id: newWorkbook.id,
                worksheetsCount: newWorkbook.worksheets ? newWorkbook.worksheets.length : 'undefined',
            });
        
            console.log('Accessing metadata sheet');
            const metadataSheet = getOrCreateMetadataSheet(newWorkbook);
            console.log('Metadata sheet accessed:', metadataSheet ? 'success' : 'failed');
        
            console.log('Processing worksheet metadata');
            if (newWorkbook.worksheets && newWorkbook.worksheets.length > 0) {
                newWorkbook.worksheets.forEach((sheet, index) => {
                    console.log(`Processing sheet ${index}: ${sheet.name}`);
                    let sheetType = metadataSheet.getCell(`B${index + 1}`).value;
                    if (!sheetType) {
                        sheetType = 'unknown';
                        setSheetTypeMetadata(newWorkbook, index, sheetType);
                    }
                    sheet.sheetType = sheetType;
                    console.log(`Sheet ${index}: ${sheet.name}, Type: ${sheetType}`);
                });
            } else {
                console.log('No worksheets found in the workbook');
            }
        
            console.log('Final Workbook State:', {
                name: newWorkbook.name,
                id: newWorkbook.id,
                worksheetsCount: newWorkbook.worksheets ? newWorkbook.worksheets.length : 'undefined',
            });
        
            if (newWorkbook.fileInfo && newWorkbook.fileInfo.handle) {
                console.log('Setting file handle');
                setLocalFileHandles(prev => ({
                    ...prev,
                    [workbookId]: newWorkbook.fileInfo.handle
                }));
                setContextFileHandles(prev => ({
                    ...prev,
                    [workbookId]: newWorkbook.fileInfo.handle
                }));
            }
        
            console.log('Updating workbooks state');
            setWorkbooks((prevWorkbooks) => {
                const updatedWorkbooks = [...prevWorkbooks, newWorkbook];
                workbooksRef.current = updatedWorkbooks;
                setSelectedWorkbookIndex(updatedWorkbooks.length - 1);
                return updatedWorkbooks;
            });
        
            console.log('Checking edit permission');
            await checkEditPermission();
            console.log('Marking as unsaved');
            markUnsaved(true);
            console.log('handleNewWorkbook completed');
        } catch (error) {
            console.error('Error in handleNewWorkbook:', error);
        }
    }, [setContextFileHandles]);

    const METADATA_SHEET_NAME = '_React2Excel_META_x7z9';
    const METADATA_SHEET_STATE = 'veryHidden'; // Change to veryHidden later

    const getOrCreateMetadataSheet = (workbook) => {
        console.log('Starting getOrCreateMetadataSheet');
        try {
            let metadataSheet = workbook.getWorksheet(METADATA_SHEET_NAME);
            if (!metadataSheet) {
                console.log('Creating new metadata sheet');
                metadataSheet = workbook.addWorksheet(METADATA_SHEET_NAME, {
                    properties: { tabColor: { argb: 'FFC0000' } },
                    state: 'veryHidden'
                });
            } else {
                console.log('Existing metadata sheet found');
                metadataSheet.state = 'veryHidden';
            }
            console.log('Metadata sheet processed successfully');
            return metadataSheet;
        } catch (error) {
            console.error('Error in getOrCreateMetadataSheet:', error);
            return null;
        }
    };

    // Function to set the sheetType in the metadata sheet
    const setSheetTypeMetadata = (workbook, index, sheetType) => {
        console.log(`Setting metadata for sheet ${index}: ${sheetType}`);
        try {
            const metadataSheet = getOrCreateMetadataSheet(workbook);
            if (metadataSheet) {
                metadataSheet.getCell(`A${index + 1}`).value = sheetType;
                console.log('Metadata set successfully');
            } else {
                console.log('Failed to set metadata: metadata sheet not available');
            }
        } catch (error) {
            console.error('Error in setSheetTypeMetadata:', error);
        }
    };
      
    const updateMetadataSheet = (workbook) => {
        console.log('Starting updateMetadataSheet');
        try {
            // Store existing metadata if it exists
            let existingMetadata = [];
            const existingMetadataSheet = workbook.getWorksheet(METADATA_SHEET_NAME);
            if (existingMetadataSheet) {
                try {
                    existingMetadataSheet.eachRow((row, rowNumber) => {
                        existingMetadata[rowNumber] = [];
                        row.eachCell((cell, colNumber) => {
                            existingMetadata[rowNumber][colNumber] = cell.value;
                        });
                    });
                    workbook.removeWorksheet(existingMetadataSheet.id);
                } catch (error) {
                    console.error('Error processing existing metadata sheet:', error);
                }
            }

            // Create or recreate metadata sheet at the end
            const metadataSheet = workbook.addWorksheet(METADATA_SHEET_NAME, {
                properties: { tabColor: { argb: 'FFC0000' } },
                state: METADATA_SHEET_STATE
            });

            // Restore existing metadata if any
            try {
                existingMetadata.forEach((row, rowNumber) => {
                    if (row) {
                        row.forEach((cellValue, colNumber) => {
                            if (cellValue !== undefined) {
                                metadataSheet.getCell(rowNumber, colNumber).value = cellValue;
                            }
                        });
                    }
                });
            } catch (error) {
                console.error('Error restoring existing metadata:', error);
            }

            // Update metadata for all visible sheets
            const visibleSheets = workbook.worksheets.filter(sheet => sheet.name !== METADATA_SHEET_NAME);
            visibleSheets.forEach((sheet, index) => {
                try {
                    metadataSheet.getCell(`A${index + 1}`).value = sheet.name;
                    metadataSheet.getCell(`B${index + 1}`).value = sheet.sheetType || 'unknown';
                    metadataSheet.getCell(`C${index + 1}`).value = index;
                } catch (error) {
                    console.error(`Error updating metadata for sheet ${sheet.name}:`, error);
                }
            });

            // Clear any extra rows if sheets were deleted
            try {
                for (let i = visibleSheets.length + 1; i <= metadataSheet.rowCount; i++) {
                    metadataSheet.getRow(i).values = [];
                }
            } catch (error) {
                console.error('Error clearing extra rows:', error);
            }

            console.log('updateMetadataSheet completed successfully');
        } catch (error) {
            console.error('Error in updateMetadataSheet:', error);
        }
    };


    useEffect(() => {
        console.log('Starting useEffect for sheets and sheetTypes');
        try {
            if (selectedWorkbookIndex !== -1) {
                console.log(`Selected workbook index: ${selectedWorkbookIndex}`);
                const workbook = workbooksRef.current[selectedWorkbookIndex];
                console.log('Workbook retrieved from ref:', workbook ? 'success' : 'failed');
                
                console.log('Converting workbook if necessary');
                const validWorkbook = forceConvertToWorkbook(workbook);
                console.log('Workbook conversion completed');
                
                console.log('Filtering visible sheets');
                const visibleSheets = validWorkbook.worksheets.filter(sheet => sheet.name !== METADATA_SHEET_NAME);
                console.log(`Number of visible sheets: ${visibleSheets.length}`);
                
                console.log('Mapping sheet types');
                const sheetTypeMap = visibleSheets.map(sheet => {
                    console.log(`Sheet: ${sheet.name}, Type: ${sheet.sheetType || 'unknown'}`);
                    return sheet.sheetType || 'unknown';
                });
                
                console.log(`Setting ${visibleSheets.length} sheets and sheet types`);
                setSheets(visibleSheets);
                setSheetTypes(sheetTypeMap);
                console.log('Sheets and sheet types set successfully');
            } else {
                console.log('No workbook selected');
            }
        } catch (error) {
            console.error('Error in useEffect for sheets and sheetTypes:', error);
        }
        console.log('Finished useEffect for sheets and sheetTypes');
    }, [selectedWorkbookIndex, workbooks, setSheets, setSheetTypes]);


    const checkEditPermission = useCallback(async () => {
        if (isCheckingPermission || selectedWorkbookIndex === -1 || selectedWorkbookIndex === lastCheckedWorkbookIndex) return false;

        setIsCheckingPermission(true);
        try {
            if (globalEditPermission === null) {
                await checkGlobalEditPermission();
            }

            if (globalEditPermission === false) {
                setHasEditPermission(false);
                setLastCheckedWorkbookIndex(selectedWorkbookIndex);
                return false;
            }

            const workbook = workbooksRef.current[selectedWorkbookIndex];
            const fileHandle = workbook.fileInfo?.handle || localFileHandles[workbook.id];

            if (!fileHandle) {
                setHasEditPermission(true);
                setLastCheckedWorkbookIndex(selectedWorkbookIndex);
                return true;
            }

            const hasPermission = await verifyPermission(fileHandle, true);
            setHasEditPermission(hasPermission);
            setLastCheckedWorkbookIndex(selectedWorkbookIndex);
            return hasPermission;
        } catch (error) {
            console.warn('Error checking edit permission:', error);
            setHasEditPermission(false);
            return false;
        } finally {
            setIsCheckingPermission(false);
        }
    }, [selectedWorkbookIndex, globalEditPermission, checkGlobalEditPermission, verifyPermission, localFileHandles, isCheckingPermission, lastCheckedWorkbookIndex]);

    useEffect(() => {
        if (selectedWorkbookIndex !== lastCheckedWorkbookIndex) {
            checkEditPermission();
        }
    }, [selectedWorkbookIndex, checkEditPermission, lastCheckedWorkbookIndex]);

    const handleSelectSheet = useCallback((sheetIndex) => {
        setWorkbooks((prevWorkbooks) => {
            const updatedWorkbooks = prevWorkbooks.map((workbook, index) => {
                if (index === selectedWorkbookIndex) {
                    return { ...workbook, selectedSheetIndex: sheetIndex };
                }
                return workbook;
            });
            return updatedWorkbooks;
        });
    }, [selectedWorkbookIndex]);

    const handleCreateSheet = useCallback(() => {
        setWorkbooks((prevWorkbooks) => {
            return prevWorkbooks.map((workbook, index) => {
                if (index === selectedWorkbookIndex) {
                    workbook = forceConvertToWorkbook(workbook);
    
                    // Generate a unique name for the new sheet
                    const baseSheetName = `Sheet ${workbook.worksheets.length}`;
                    let newSheetName = baseSheetName;
                    let counter = 1;
                    while (workbook.getWorksheet(newSheetName)) {
                        newSheetName = `${baseSheetName} (${counter})`;
                        counter++;
                    }
    
                    const newSheet = workbook.addWorksheet(newSheetName);
                    newSheet.sheetType = 'unknown';
    
                    // Update metadata sheet
                    updateMetadataSheet(workbook);
    
                    // Filter out the metadata sheet before setting the visible sheets
                    const visibleSheets = workbook.worksheets.filter(sheet => sheet.name !== METADATA_SHEET_NAME);
                    setSheets(visibleSheets);
    
                    return { ...workbook, selectedSheetIndex: visibleSheets.length - 1 };
                }
                return workbook;
            });
        });
        markUnsaved();
    }, [selectedWorkbookIndex, markUnsaved]);

    const handleDeleteSheet = useCallback((sheetIndex) => {
        setWorkbooks((prevWorkbooks) => {
            return prevWorkbooks.map((workbook, index) => {
                if (index === selectedWorkbookIndex) {
                    workbook = forceConvertToWorkbook(workbook);
                    const visibleSheets = workbook.worksheets.filter(sheet => sheet.name !== METADATA_SHEET_NAME);
                    
                    if (visibleSheets.length <= 1) {
                        alert("Cannot delete the only sheet.");
                        return workbook;
                    }
    
                    const sheetToDelete = visibleSheets[sheetIndex];
                    // console.log(`Deleting worksheet: ${sheetToDelete.name} at ExcelJS index ${workbook.worksheets.indexOf(sheetToDelete) + 1}`);
    
                    // Remove the worksheet
                    workbook.removeWorksheet(sheetToDelete.id);
    
                    // Update metadata sheet
                    updateMetadataSheet(workbook);
    
                    const updatedVisibleSheets = workbook.worksheets.filter(sheet => sheet.name !== METADATA_SHEET_NAME);
                    setSheets(updatedVisibleSheets);
    
                    return { ...workbook, selectedSheetIndex: Math.min(sheetIndex, updatedVisibleSheets.length - 1) };
                }
                return workbook;
            });
        });
        markUnsaved();
    }, [selectedWorkbookIndex, markUnsaved]);


    const handleDuplicateSheet = useCallback((index) => {
        setWorkbooks((prevWorkbooks) => {
            return prevWorkbooks.map((workbook, wbIndex) => {
                if (wbIndex === selectedWorkbookIndex) {
                    workbook = forceConvertToWorkbook(workbook);
                    const sourceSheet = workbook.worksheets[index];
    
                    // Generate a unique name for the duplicate sheet
                    let baseName = `${sourceSheet.name} Copy`;
                    let uniqueName = baseName;
                    const existingNames = workbook.worksheets.map(sheet => sheet.name);
                    let counter = 1;
                    while (existingNames.includes(uniqueName)) {
                        uniqueName = `${baseName} (${counter})`;
                        counter++;
                    }
    
                    // Add the new duplicate sheet with a unique name
                    const newSheet = workbook.addWorksheet(uniqueName);
    
                    // Copy the contents and properties of the source sheet to the new sheet
                    sourceSheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
                        const newRow = newSheet.getRow(rowNumber);
                        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
                            newRow.getCell(colNumber).value = cell.value;
                            newRow.getCell(colNumber).style = { ...cell.style };
                        });
                    });
    
                    // Copy the sheetType
                    newSheet.sheetType = sourceSheet.sheetType || 'unknown';
    
                    // Update metadata sheet
                    updateMetadataSheet(workbook);
    
                    const visibleSheets = workbook.worksheets.filter(sheet => sheet.name !== METADATA_SHEET_NAME);
                    setSheets(visibleSheets);
    
                    return { ...workbook, selectedSheetIndex: visibleSheets.length - 1 };
                }
                return workbook;
            });
        });
        markUnsaved();
    }, [selectedWorkbookIndex, markUnsaved]);


    const handleUpdateSheetType = useCallback((sheetIndex, newType) => {
        setWorkbooks((prevWorkbooks) => {
          return prevWorkbooks.map((workbook, index) => {
            if (index === selectedWorkbookIndex) {
              workbook = forceConvertToWorkbook(workbook);
              if (workbook.worksheets[sheetIndex]) {
                workbook.worksheets[sheetIndex].sheetType = newType;
                updateMetadataSheet(workbook);
                setSheets([...workbook.worksheets]);
              }
              return { ...workbook };
            }
            return workbook;
          });
        });
        markUnsaved();
    }, [selectedWorkbookIndex, markUnsaved]);


    const handleRenameSheet = useCallback((index, newName) => {
        if (typeof newName === 'string' && newName.trim() !== '') {
          setWorkbooks((prevWorkbooks) => {
            return prevWorkbooks.map((workbook, wbIndex) => {
              if (wbIndex === selectedWorkbookIndex) {
                workbook = forceConvertToWorkbook(workbook);
                if (workbook.worksheets[index]) {
                  workbook.worksheets[index].name = newName.trim();
                  updateMetadataSheet(workbook);
                  setSheets([...workbook.worksheets]);
                }
              }
              return workbook;
            });
          });
          markUnsaved();
        }
    }, [selectedWorkbookIndex, markUnsaved]);

    

    const handleSave = useCallback(async () => {
        console.log('Starting handleSave');
        if (selectedWorkbookIndex === -1) {
            console.log('No workbook selected, aborting save');
            return;
        }

        try {
            const workbook = workbooksRef.current[selectedWorkbookIndex];
            if (!workbook) {
                throw new Error('Selected workbook not found in ref');
            }

            console.log('Updating metadata sheet');
            const metadataSheet = getOrCreateMetadataSheet(workbook);
            if (!metadataSheet) {
                throw new Error('Failed to create or retrieve metadata sheet');
            }

            // Persist the sheetType metadata in the Metadata sheet
            sheets.forEach((sheet, index) => {
                try {
                    const sheetType = sheet.sheetType;
                    if (sheetType) {
                        metadataSheet.getCell(`A${index + 1}`).value = sheetType;
                    }
                } catch (error) {
                    console.error(`Error updating metadata for sheet ${index}:`, error);
                }
            });

            const fileName = workbook.fileInfo?.fileName || workbook.name || 'NewWorkbook.xlsx';
            const fileHandle = workbook.fileInfo?.handle || localFileHandles[workbook.id];

            console.log('File System Access API supported:', isFileSystemAccessSupported);
            console.log('File handle exists:', !!fileHandle);

            if (isFileSystemAccessSupported) {
                if (fileHandle) {
                    console.log('Attempting to save to existing file handle');
                    try {
                        console.log('Creating writable');
                        const writable = await fileHandle.createWritable();
                        console.log('Writing workbook');
                        await workbook.xlsx.write(writable);
                        console.log('Closing writable');
                        await writable.close();
                        console.log('File saved successfully');
                    } catch (error) {
                        console.error('Error during file save:', error);
                        if (error.name === 'NotAllowedError') {
                            console.log('Permission denied, requesting permission');
                            const hasPermission = await verifyPermission(fileHandle, true);
                            console.log('Permission granted:', hasPermission);
                            if (hasPermission) {
                                console.log('Retrying save with new permissions');
                                const writable = await fileHandle.createWritable();
                                await workbook.xlsx.write(writable);
                                await writable.close();
                                console.log('File saved successfully after permission grant');
                            } else {
                                throw new Error('Write permission denied');
                            }
                        } else {
                            throw error;
                        }
                    }
                } else {
                    console.log('Saving as new file');
                    await saveWorkbook(workbook, fileName, workbook.id);
                }
            } else {
                console.log('Falling back to download method');
                // Fallback to traditional download method
                const buffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                URL.revokeObjectURL(url);
            }

            markUnsaved(true);
            console.log('Workbook saved successfully');
        } catch (error) {
            console.error('Error in handleSave:', error);
            // Show an error message to the user
            alert('Failed to save workbook. Please try again.');
        }
    }, [selectedWorkbookIndex, saveWorkbook, markUnsaved, verifyPermission, localFileHandles, sheets, isFileSystemAccessSupported]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 's') {
                event.preventDefault();
                handleSave();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleSave]);

    return (
        <div className="flex flex-col">
            {/* <div className='flex px-4 py-3 w-full'>
                <WorkbookTabs
                    workbooks={workbooks}
                    selectedWorkbookIndex={selectedWorkbookIndex}
                    onSelect={handleSelectWorkbook}
                    onClose={handleCloseWorkbook}
                    onNew={() => handleNewWorkbook(createWorkbook())}
                />
            </div> */}
            <div className="flex-grow min-h-screen">
                {selectedWorkbookIndex === -1 ? (
                    <NewWorkbook onNewWorkbook={handleNewWorkbook} />
                ) : (
                    <Workbook
                        workbookData={workbooks[selectedWorkbookIndex]}
                        workbookName={workbooks[selectedWorkbookIndex].name}
                        onSave={handleSave}
                        isSaved={isSaved}
                        hasEditPermission={hasEditPermission}
                        view={view}
                        setView={setView}
                        selectedSheet={workbooks[selectedWorkbookIndex].selectedSheetIndex}
                        onSelectSheet={handleSelectSheet}
                        onCreateSheet={handleCreateSheet}
                        onDeleteSheet={handleDeleteSheet}
                        onRenameSheet={handleRenameSheet}
                        onDuplicateSheet={handleDuplicateSheet}
                        markUnsaved={markUnsaved}
                        sheets={sheets}
                        setSheets={setSheets}
                        sheetTypes={sheetTypes}
                        onUpdateSheetType={handleUpdateSheetType}
                    />
                )}
            </div>
        </div>
    );
};

export default WorkbookManager;
