import React, { useState, useEffect, useCallback } from 'react';
import Column from '../Columns/column';
import NewColumn from '../Columns/newColumn';
import { useDragDropContext } from '../Configs/DragDropContextProvider';
import { columnIndexToLetter, parseItemsInColumn, processDragResult, updateWorksheetFromState, getAllUniqueTags, getAllUniqueAssignees } from './columnUtils';
import { useItemConfig } from '../Configs/ItemConfigContext';

const Columns = ({ worksheet, selectedSheetIndex, markUnsaved}) => {
    const { config } = useItemConfig();
    const { registerDragEndHandler } = useDragDropContext();
    const [columnsData, setColumnsData] = useState([]);
    const [uniqueTags, setUniqueTags] = useState([]);
    const [uniqueAssignees, setUniqueAssignees] = useState([]);
    const [isAddingColumn, setIsAddingColumn] = useState(false);

    const parseColumnsAndItems = useCallback(async (worksheet, config) => {
        if (!worksheet || typeof worksheet.getRow !== 'function') {
            console.error('Invalid worksheet object or missing getRow method:', worksheet);
            return;
        }

        const firstRow = worksheet.getRow(1);
        const tempColumnsData = firstRow.values
            .map((value, index) => ({
                index,
                name: value,
                position: columnIndexToLetter(index),
                items: []
            }))
            .filter(col => col.name);

        for (const column of tempColumnsData) {
            column.items = await parseItemsInColumn(worksheet, column.index, config);
        }

        setColumnsData(tempColumnsData);
    }, []);

    useEffect(() => {
        if (worksheet) {
            parseColumnsAndItems(worksheet, config);
        }
    }, [worksheet, config, parseColumnsAndItems]);


    // ----- ITEMS -----

    const onDragEnd = useCallback((result) => {
        if (result.type !== 'COLUMN') return;
        if (!columnsData || columnsData.length === 0) return;
        if (worksheet.orderNo !== selectedSheetIndex) return;

        try {
            setColumnsData((prevColumnsData) => {
                const updatedColumnsData = processDragResult(prevColumnsData, result);
                updateWorksheetFromState(worksheet, updatedColumnsData, config);
                markUnsaved();
                return updatedColumnsData;
            });
        } catch (error) {
            console.error('Failed to update column data:', error);
        }
    }, [columnsData, worksheet, config, selectedSheetIndex, markUnsaved]);

    useEffect(() => {
        const unregisterDragEndHandler = registerDragEndHandler(onDragEnd);
        return () => {
            unregisterDragEndHandler();
        };
    }, [onDragEnd, registerDragEndHandler]);

    const addItem = useCallback((columnIndex, itemData, addToTop = true) => {
        setColumnsData(prevColumnsData => {
            const colIndex = prevColumnsData.findIndex(col => col.index === columnIndex);
            if (colIndex === -1) {
                console.error('Invalid column index:', columnIndex);
                return prevColumnsData;
            }

            const newItems = addToTop
                ? [itemData, ...prevColumnsData[colIndex].items]
                : [...prevColumnsData[colIndex].items, itemData];
            const newColumnsData = [...prevColumnsData];
            newColumnsData[colIndex] = {
                ...prevColumnsData[colIndex],
                items: newItems
            };

            updateWorksheetFromState(worksheet, newColumnsData, config);
            return newColumnsData;
        });
    }, [worksheet, config]);

    const updateItem = useCallback((columnIndex, itemData) => {
        setColumnsData(prevColumnsData => {
            const colIndex = prevColumnsData.findIndex(col => col.index === columnIndex);
            if (colIndex === -1) {
                console.error('Invalid column index:', columnIndex);
                return prevColumnsData;
            }

            const itemIndex = prevColumnsData[colIndex].items.findIndex(item => item.id === itemData.id);
            if (itemIndex === -1) {
                console.error('Item not found:', itemData.id);
                return prevColumnsData;
            }

            const updatedItems = prevColumnsData[colIndex].items.map((item, index) =>
                index === itemIndex ? { ...item, ...itemData } : item
            );
            const updatedColumnsData = [...prevColumnsData];
            updatedColumnsData[colIndex] = {
                ...prevColumnsData[colIndex],
                items: updatedItems
            };

            updateWorksheetFromState(worksheet, updatedColumnsData, config);
            return updatedColumnsData;
        });
    }, [worksheet, config]);

    const deleteItem = useCallback((columnIndex, itemId) => {
        setColumnsData(prevColumnsData => {
            const colIndex = prevColumnsData.findIndex(col => col.index === columnIndex);
            if (colIndex === -1) {
                console.error('Invalid column index:', columnIndex);
                return prevColumnsData;
            }

            const updatedItems = prevColumnsData[colIndex].items.filter(item => item.id !== itemId);
            const newColumnsData = [...prevColumnsData];
            newColumnsData[colIndex] = {
                ...prevColumnsData[colIndex],
                items: updatedItems
            };

            updateWorksheetFromState(worksheet, newColumnsData, config);
            return newColumnsData;
        });
    }, [worksheet, config]);


    // ----- COLUMNS -----

    const addNewColumn = async () => {
        if (!worksheet || typeof worksheet.getRow !== 'function') {
            console.error('Invalid worksheet object or missing getRow method:', worksheet);
            return;
        }

        const lastColumnIndex = columnsData.length > 0 ? columnsData[columnsData.length - 1].index : 0;
        const newColumnIndex = lastColumnIndex + 2; // Leave an empty column after the last column with content

        const newColumn = {
            index: newColumnIndex,
            name: 'Column',
            position: columnIndexToLetter(newColumnIndex),
            items: []
        };

        setColumnsData(prevColumnsData => {
            const newColumnsData = [...prevColumnsData, newColumn];
            updateWorksheetFromState(worksheet, newColumnsData, config);
            return newColumnsData;
        });

        // Update the worksheet with the new column
        try {
            // Assuming row 1 is headers
            const headerRow = worksheet.getRow(1);
            headerRow.getCell(newColumnIndex).value = `Column ${newColumnIndex}`;
            headerRow.commit();
        } catch (error) {
            console.error('Failed to update worksheet:', error);
        }
    };

    const updateColumnName = async (columnIndex, newName) => {
        if (!worksheet || typeof worksheet.getRow !== 'function') {
            console.error('Invalid worksheet object or missing getRow method:', worksheet);
            return;
        }

        setColumnsData(prevColumnsData => {
            const updatedColumnsData = prevColumnsData.map(col => {
                if (col.index === columnIndex) {
                    col.name = newName;
                }
                return col;
            });
            updateWorksheetFromState(worksheet, updatedColumnsData, config);
            return updatedColumnsData;
        });

        // Update the worksheet with the new column name
        try {
            const headerRow = worksheet.getRow(1);
            headerRow.getCell(columnIndex).value = newName;
            headerRow.commit();
        } catch (error) {
            console.error('Failed to update worksheet:', error);
        }
    };

    const removeColumn = async (columnIndex) => {
        if (!worksheet || typeof worksheet.getRow !== 'function') {
            console.error('Invalid worksheet object or missing getRow method:', worksheet);
            return;
        }

        setColumnsData(prevColumnsData => {
            const updatedColumnsData = prevColumnsData.filter(col => col.index !== columnIndex);
            updateWorksheetFromState(worksheet, updatedColumnsData, config);
            return updatedColumnsData;
        });

        // Update the worksheet to remove the column
        try {
            const column = worksheet.getColumn(columnIndex);
            column.eachCell(cell => {
                cell.value = null;
                cell.style = {};
            });
        } catch (error) {
            console.error('Failed to update worksheet:', error);
        }
    };

    // ----- TAGS -----

    useEffect(() => {
        const tags = getAllUniqueTags(columnsData);
        setUniqueTags(tags);
        
        const assignees = getAllUniqueAssignees(columnsData);
        setUniqueAssignees(assignees);
    }, [columnsData]);

    const handleAddColumn = useCallback((columnName) => {
        if (!worksheet || typeof worksheet.getRow !== 'function') {
            console.error('Invalid worksheet object or missing getRow method:', worksheet);
            return;
        }

        const lastColumnIndex = columnsData.length > 0 ? columnsData[columnsData.length - 1].index : 0;
        const newColumnIndex = lastColumnIndex + 2;

        const newColumn = {
            index: newColumnIndex,
            name: columnName,
            position: columnIndexToLetter(newColumnIndex),
            items: []
        };

        setColumnsData(prevColumnsData => {
            const newColumnsData = [...prevColumnsData, newColumn];
            updateWorksheetFromState(worksheet, newColumnsData, config);
            return newColumnsData;
        });

        try {
            const headerRow = worksheet.getRow(1);
            headerRow.getCell(newColumnIndex).value = columnName;
            headerRow.commit();
        } catch (error) {
            console.error('Failed to update worksheet:', error);
        }

        setIsAddingColumn(false);
        markUnsaved();
    }, [columnsData, worksheet, config, markUnsaved]);

    return (
        <div className="flex flex-nowrap overflow-x-auto space-x-1 px-4 pb-3.5 h-full">
            {columnsData.map((column, index) => (
                <Column
                    key={index}
                    column={column}
                    items={column.items}
                    columnIndex={column.index}
                    columnPosition={column.position}
                    worksheet={worksheet}
                    addItem={addItem}
                    updateItem={updateItem}
                    deleteItem={deleteItem}
                    updateColumnName={updateColumnName}
                    removeColumn={removeColumn}
                    markUnsaved={markUnsaved}
                    uniqueTags={uniqueTags}
                    uniqueAssignees={uniqueAssignees}
                />
            ))}
            {isAddingColumn ? (
                <NewColumn onAddColumn={handleAddColumn} onCancel={() => setIsAddingColumn(false)} />
            ) : (
                <button
                    onClick={() => setIsAddingColumn(true)}
                    className="w-72 min-w-72 max-w-72 flex px-2.5 rounded-lg border border-transparent"
                >
                    <div className="flex w-full items-center py-4 text-lg font-bold leading-none text-zinc-400">
                        Add column
                    </div>
                </button>
            )}
        </div>
    );
};

export default Columns;
