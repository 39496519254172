import React, { useCallback } from 'react';
import Columns from '../Columns/columns';
import List from '../List/list';
import Grid from '../Grid/grid';

const Sheet = ({ worksheet, view, selectedSheetIndex, workbookData, markUnsaved, sheetType }) => {
    // console.log('Rendering Sheet component with worksheet:', worksheet, "with sheet type", sheetType);

    const renderComponentBasedOnSheetType = (sheetType) => {
        switch (sheetType) {
            case 'kanban':
                return <Columns worksheet={worksheet} selectedSheetIndex={selectedSheetIndex} markUnsaved={markUnsaved} />;
            case 'gantt':
                return <Columns worksheet={worksheet} selectedSheetIndex={selectedSheetIndex} markUnsaved={markUnsaved} />;
            case 'calendar':
                return <Columns worksheet={worksheet} selectedSheetIndex={selectedSheetIndex} markUnsaved={markUnsaved} />;
            case 'list':
                return <List worksheet={worksheet} />;
            default:
                return <Columns worksheet={worksheet} selectedSheetIndex={selectedSheetIndex} markUnsaved={markUnsaved} />;
        }
    };

    return (
        <div className="flex-grow h-full">
            {renderComponentBasedOnSheetType(sheetType || 'default')}
        </div>
    );
};

// const Sheet = ({ worksheet, view, selectedSheetIndex, workbookData, markUnsaved }) => {
//     console.log('Rendering Sheet component with worksheet:', worksheet);

//     return (
//         <div className='flex-grow h-full'>
//             <div 
//                 style={{ display: view === 'columns' ? 'block' : 'none' }}
//                 className='h-full'
//             >
//                 <Columns 
//                     worksheet={worksheet} 
//                     selectedSheetIndex={selectedSheetIndex} 
//                     markUnsaved={markUnsaved}
//                 />
//             </div>
//             <div 
//                 style={{ display: view === 'grid' ? 'block' : 'none' }}
//                 className='h-full'
//             >
//                 <Grid 
//                     worksheet={worksheet} 
//                     markUnsaved={markUnsaved}  
//                 />
//             </div>
//         </div>
//     );
// };

export default Sheet;