import React, { useState, useEffect, useRef } from 'react';
import { CalendarIcon } from "lucide-react";
import { parse, format } from 'date-fns';

const Dates = ({ startCellContent, endCellContent, editable = false, onStartChange, onEndChange }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState({ start: false, end: false });
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const calendarRef = useRef(null);

    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const parseExcelDate = (excelDate) => {
        return new Date((excelDate - 25569) * 86400 * 1000);
    };

    const toExcelDate = (date) => {
        return (date.getTime() / 86400000 + 25569).toFixed(5);
    };

    const parseDate = (dateString) => {
        if (!dateString) return null;
        
        // First, try parsing as MM/DD/YYYY
        let parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
        
        // If parsing fails, try Excel date format
        if (isNaN(parsedDate)) {
            parsedDate = parseExcelDate(Number(dateString));
        }
        
        return isNaN(parsedDate) ? null : parsedDate;
    };

    useEffect(() => {
        if (startCellContent) {
            const parsedStartDate = parseDate(startCellContent);
            console.log('Reading start date:', startCellContent, 'Parsed:', parsedStartDate);
            setStartDate(parsedStartDate);
        }
        if (endCellContent) {
            const parsedEndDate = parseDate(endCellContent);
            console.log('Reading end date:', endCellContent, 'Parsed:', parsedEndDate);
            setEndDate(parsedEndDate);
        }
    }, [startCellContent, endCellContent]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar({ start: false, end: false });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const formatDate = (date) => {
        return date ? format(date, 'MMMM d, yyyy') : '';
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1).getDay();
    };

    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
    };

    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
    };

    const handleDateClick = (day, isStart) => {
        const newDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        if (isStart) {
            setStartDate(newDate);
            if (onStartChange) {
                const formattedDate = format(newDate, 'MM/dd/yyyy');
                console.log('Saving start date:', newDate, 'Formatted:', formattedDate);
                onStartChange(formattedDate);
            }
            // Ensure end date is not before the new start date
            if (endDate && newDate > endDate) {
                setEndDate(null);
                if (onEndChange) {
                    onEndChange('');
                }
            }
        } else {
            // Only allow setting end date if it's after the start date
            if (startDate && newDate > startDate) {
                setEndDate(newDate);
                if (onEndChange) {
                    const formattedDate = format(newDate, 'MM/dd/yyyy');
                    console.log('Saving end date:', newDate, 'Formatted:', formattedDate);
                    onEndChange(formattedDate);
                }
            }
        }
        setShowCalendar({ start: false, end: false });
    };
    
    const renderCalendar = (isStart) => {
        const daysInMonth = getDaysInMonth(currentMonth.getFullYear(), currentMonth.getMonth());
        const firstDayOfMonth = getFirstDayOfMonth(currentMonth.getFullYear(), currentMonth.getMonth());
        const days = [];
    
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<div key={`empty-${i}`} className="w-8 h-8"></div>);
        }
    
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
            const isSelected = isStart 
                ? startDate && currentDate.getTime() === startDate.getTime()
                : endDate && currentDate.getTime() === endDate.getTime();
            const isToday = new Date().toDateString() === currentDate.toDateString();
            const isDisabled = !isStart && startDate && currentDate <= startDate;
    
            days.push(
                <button
                    key={day}
                    onClick={() => handleDateClick(day, isStart)}
                    className={`w-8 h-8 rounded-full flex items-center justify-center text-sm
                                ${isSelected ? 'bg-blue-500 text-white' : 
                                  isToday ? 'bg-blue-100' : 'hover:bg-gray-200'}
                                ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
                    disabled={isDisabled}
                >
                    {day}
                </button>
            );
        }
    
        return days;
    };

    if (!editable) {
        if (!startDate && !endDate) return null;

        const displayDates = `${formatDate(startDate)} - ${formatDate(endDate)}`;

        return (
            <span className="inline-flex items-center gap-x-1 rounded px-2 py-1 text-xs font-medium text-zinc-500 ring-1 ring-inset ring-zinc-200">
                <CalendarIcon className="w-3 h-3" />
                {displayDates}
            </span>
        );
    }

    const renderDateInput = (isStart) => (
        <div className="relative">
            <input
                type="text"
                value={isStart ? formatDate(startDate) || 'Select start date' : formatDate(endDate) || 'Select end date'}
                onClick={() => {
                    if (isStart || startDate) {
                        setShowCalendar({ start: isStart, end: !isStart });
                    }
                }}
                readOnly
                className={`w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent cursor-pointer
                            ${!isStart && !startDate ? 'cursor-not-allowed opacity-50' : ''}`}
                disabled={!isStart && !startDate}
            />
            <CalendarIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
        </div>
    );

    return (
        <div className="relative" ref={calendarRef}>
            <div className="flex gap-2">
                {renderDateInput(true)}
                {renderDateInput(false)}
            </div>
            {(showCalendar.start || showCalendar.end) && (
                <div className="absolute mt-2 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                    <div className="flex justify-between items-center p-2 border-b">
                        <button onClick={handlePrevMonth} className="p-1 hover:bg-gray-100 rounded">
                            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                        <div className="font-semibold">
                            {months[currentMonth.getMonth()]} {currentMonth.getFullYear()}
                        </div>
                        <button onClick={handleNextMonth} className="p-1 hover:bg-gray-100 rounded">
                            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    </div>
                    <div className="p-2">
                        <div className="grid grid-cols-7 gap-1 mb-2">
                            {daysOfWeek.map(day => (
                                <div key={day} className="text-center text-gray-600 text-xs">
                                    {day}
                                </div>
                            ))}
                        </div>
                        <div className="grid grid-cols-7 gap-1">
                            {renderCalendar(showCalendar.start)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dates;


