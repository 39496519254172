import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu, Transition } from '@headlessui/react';
import { PlusIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import Sheet from '../Sheet/sheet';
import { useDragDropContext } from '../Configs/DragDropContextProvider';
import { Dialog } from '@headlessui/react';

const MAX_SHEETS = 5;
const DROPDOWN_WIDTH = 192; // 12rem in pixels
const MAX_NAME_LENGTH = 31; // Excel's maximum sheet name length

const Workbook = ({
    workbookData,
    workbookName,
    onSave,
    isSaved,
    hasEditPermission,
    view,
    setView,
    selectedSheet,
    onSelectSheet,
    onCreateSheet,
    onDeleteSheet,
    onRenameSheet,
    onDuplicateSheet,
    markUnsaved,
    sheets,
    setSheets,
    onUpdateSheetType,
}) => {
    const [selectedSheetIndex, setSelectedSheetIndex] = useState(0);
    const [editingSheetIndex, setEditingSheetIndex] = useState(null);
    const [isSheetTypeDialogOpen, setIsSheetTypeDialogOpen] = useState(false);
    const [sheetType, setSheetType] = useState('');

    const { registerDragEndHandler } = useDragDropContext();

    useEffect(() => {
        if (!workbookData || !workbookData.worksheets) return;
        
        // Ensure the filtering works for both 'hidden' and 'veryHidden'
        const visibleSheets = workbookData.worksheets.filter(sheet => {
            const isHidden = sheet.hidden || sheet.state === 'hidden';
            const isVeryHidden = sheet.veryHidden || sheet.state === 'veryHidden';
            return !isHidden && !isVeryHidden;
        });
    
        if (visibleSheets.length > 0) {
            console.log('Setting visible sheets from workbookData:', visibleSheets);
            setSheets([...visibleSheets]);
        }
    }, [workbookData, setSheets]);

    useEffect(() => {
        if (selectedSheet !== undefined && selectedSheet !== selectedSheetIndex) {
            setSelectedSheetIndex(selectedSheet);
        }
    }, [selectedSheet]); // Remove selectedSheetIndex from the dependency array

    useEffect(() => {
        if (sheets[selectedSheetIndex]) {
            const type = sheets[selectedSheetIndex].sheetType || '';
            if (type !== sheetType) {
                setSheetType(type);
                if (!type || type.toLowerCase() === 'unknown') {
                    setIsSheetTypeDialogOpen(true);
                }
            }
        }
    }, [selectedSheetIndex, sheets, sheetType]);

    const selectSheet = useCallback((index) => {
        if (index !== selectedSheetIndex) {
            onSelectSheet(index);
        }
    }, [selectedSheetIndex, onSelectSheet]);

    const handleSheetTypeSelection = (type) => {
        setSheets((prevSheets) => {
            const updatedSheets = prevSheets.map((sheet, index) =>
                index === selectedSheetIndex ? { ...sheet, sheetType: type } : sheet
            );
            return updatedSheets;
        });
        onUpdateSheetType(selectedSheetIndex, type);
        setSheetType(type);
        setIsSheetTypeDialogOpen(false);
        markUnsaved();
    };


    const openSheetTypeDialog = () => {
        setIsSheetTypeDialogOpen(true);
    };

    // Function to handle the "Open file" action
    const handleOpenFile = () => {
        if (!isSaved) {
            const confirmChange = window.confirm("You have unsaved changes. Do you want to discard them?");
            if (!confirmChange) return;
        }
        // Logic to open a different file
        // Example placeholder:
        console.log("Open file action triggered");
    };

    // Function to handle the "New file" action
    const handleNewFile = () => {
        if (!isSaved) {
            const confirmChange = window.confirm("You have unsaved changes. Do you want to discard them?");
            if (!confirmChange) return;
        }
        // Logic to create a new file
        // Example placeholder:
        console.log("New file action triggered");
    };

    const handleCreateSheet = () => {
        if (sheets.length < MAX_SHEETS) {
            onCreateSheet();
            markUnsaved();
        }
    };

    const handleDeleteSheet = (index) => {
        onDeleteSheet(index);
    };

    const handleRenameSheet = useCallback((index, newName) => {
        newName = newName.trim().slice(0, MAX_NAME_LENGTH);

        if (newName && newName !== sheets[index].name) {
            let uniqueName = newName;
            const existingNames = sheets.map(sheet => sheet.name);
            let counter = 1;

            while (existingNames.includes(uniqueName) && uniqueName !== sheets[index].name) {
                uniqueName = `${newName} (${counter})`;
                counter++;
            }

            setSheets((prevSheets) => {
                const updatedSheets = prevSheets.map((sheet, i) => 
                    i === index ? { ...sheet, name: uniqueName } : sheet
                );
                return updatedSheets;
            });

            onRenameSheet(index, uniqueName);
            markUnsaved();
            setEditingSheetIndex(null);
        }
    }, [sheets, onRenameSheet, markUnsaved]);

    const handleDuplicateSheet = (index) => {
        if (sheets.length < MAX_SHEETS) {
            onDuplicateSheet(index);
            markUnsaved();
        }
    };

    const onDragEnd = useCallback((result) => {
        const { source, destination, type } = result;

        if (type !== 'SHEETS' || !destination) {
            return;
        }

        setSheets((prevSheets) => {
            const reorderedSheets = Array.from(prevSheets);
            const [removed] = reorderedSheets.splice(source.index, 1);
            reorderedSheets.splice(destination.index, 0, removed);

            reorderedSheets.forEach((sheet, index) => {
                sheet.orderNo = index;
            });

            return reorderedSheets;
        });

        setSelectedSheetIndex((prevSelectedSheetIndex) => {
            let newIndex = prevSelectedSheetIndex;

            if (prevSelectedSheetIndex === source.index) {
                newIndex = destination.index;
            } else if (prevSelectedSheetIndex > source.index && prevSelectedSheetIndex <= destination.index) {
                newIndex -= 1;
            } else if (prevSelectedSheetIndex < source.index && prevSelectedSheetIndex >= destination.index) {
                newIndex += 1;
            }

            return newIndex;
        });

        onSelectSheet(destination.index);
        markUnsaved();
    }, [onSelectSheet, markUnsaved]);

    useEffect(() => {
        const deregisterDragEndHandler = registerDragEndHandler(onDragEnd);
        return () => {
            deregisterDragEndHandler();
        };
    }, [registerDragEndHandler, onDragEnd]);

    return (
        <div className="flex flex-col min-h-screen">
            <nav className="flex items-center px-3 py-1.5">
                <div className="flex items-center justify-center w-auto whitespace-nowrap mr-12">
                    <div className='ml-2 mr-0.5 flex-shrink-0'>
                        <img 
                            src="/logo_svg.svg" 
                            alt="Logo" 
                            className="h-3 w-3 object-contain"
                        />
                    </div>
                    <Menu as="div" className="relative inline-block text-left">
                        <div>
                            <Menu.Button className="flex items-center w-full text-sm font-medium text-zinc-700 rounded-md hover:bg-gray-200 focus:outline-none transition-colors">
                                <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={React.Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute left-0 mt-2 w-56 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={handleOpenFile}
                                                className={`${
                                                    active ? 'bg-gray-100' : ''
                                                } group flex items-center w-full px-4 py-2 text-sm text-gray-700`}
                                            >
                                                Open file
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={handleNewFile}
                                                className={`${
                                                    active ? 'bg-gray-100' : ''
                                                } group flex items-center w-full px-4 py-2 text-sm text-gray-700`}
                                            >
                                                New file
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <div className="text-sm font-medium ml-2">{workbookName}</div>
                </div>

                <div className="flex-grow"></div>

                <div className="min-w-80 flex justify-end items-center">
                    <SaveButton onClick={onSave} isSaved={isSaved} hasEditPermission={hasEditPermission} />
                </div>
            </nav>

            
            <div className="w-full flex mx-auto items-stretch border-t border-zinc-200 bg-zinc-50">
                
                <Droppable droppableId="sheets" type="SHEETS" direction="horizontal">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="flex items-stretch overflow-x-auto scroll-container"
                        >
                            {sheets.map((sheet, index) => (
                                <Draggable key={sheet.id || index} draggableId={sheet.id?.toString() || index.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`draggable-item inline-flex items-center px-2 pl-6 py-1.5 text-xs font-semibold flex-shrink-0 border-b border-r border-zinc-200 group
                                                ${index === selectedSheetIndex ? 'bg-white text-zinc-900 border-b-transparent' : 'bg-zinc-50 text-zinc-500'}
                                                ${snapshot.isDragging ? 'bg-zinc-200' : ''}`}
                                            onClick={() => selectSheet(index)}
                                        >
                                            <SheetName
                                                sheet={sheet}
                                                index={index}
                                                isEditing={editingSheetIndex === index}
                                                onRename={(newName) => handleRenameSheet(index, newName)}
                                                setEditingSheetIndex={setEditingSheetIndex}
                                            />
                                            <SheetMenu
                                                sheet={sheet}
                                                index={index}
                                                handleRenameSheet={() => setEditingSheetIndex(index)}
                                                handleDeleteSheet={handleDeleteSheet}
                                                handleDuplicateSheet={handleDuplicateSheet}
                                                maxSheets={MAX_SHEETS}
                                                sheetsCount={sheets.length}
                                                selectedSheetIndex={selectedSheetIndex}
                                                openSheetTypeDialog={openSheetTypeDialog}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>

                <button
                    onClick={handleCreateSheet}
                    id="sheetSelectorAddSheetButton"
                    className={`flex items-center px-3 text-sm font-medium text-zinc-700 hover:bg-zinc-100 transition-colors border-b border-zinc-200 ${
                        sheets.length >= MAX_SHEETS ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={sheets.length >= MAX_SHEETS}
                >
                    
                    <PlusIcon className="h-4 w-4 text-zinc-500" aria-hidden="true" />
                    
                </button>
                
                <div id="sheetSelectorFiller" className="flex-grow border-b border-zinc-200"></div>
        </div>
            
            <div className="flex-grow h-full">
                <Sheet 
                    worksheet={sheets[selectedSheetIndex]} 
                    view={view} 
                    selectedSheetIndex={selectedSheetIndex}
                    workbookData={workbookData}
                    markUnsaved={markUnsaved}
                    sheetType={sheetType}
                />
            </div>

            {/* Sheet Type Dialog */}
            {isSheetTypeDialogOpen && (
                <Dialog open={isSheetTypeDialogOpen} onClose={() => setIsSheetTypeDialogOpen(false)}>
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-25">
                        <div className="bg-white rounded p-6 max-w-sm mx-auto">
                            <h2 className="text-lg font-semibold">Select Sheet Type</h2>
                            <div className="mt-4">
                                <button
                                    className="block w-full text-left p-2 hover:bg-gray-100"
                                    onClick={() => handleSheetTypeSelection('kanban')}
                                >
                                    Kanban
                                </button>
                                <button
                                    className="block w-full text-left p-2 hover:bg-gray-100"
                                    onClick={() => handleSheetTypeSelection('gantt')}
                                >
                                    Gantt
                                </button>
                                <button
                                    className="block w-full text-left p-2 hover:bg-gray-100"
                                    onClick={() => handleSheetTypeSelection('list')}
                                >
                                    List
                                </button>
                                <button
                                    className="block w-full text-left p-2 hover:bg-gray-100"
                                    onClick={() => handleSheetTypeSelection('calendar')}
                                >
                                    Calendar
                                </button>
                            </div>
                            <div className="mt-4">
                                <button
                                    className="text-blue-500"
                                    onClick={() => setIsSheetTypeDialogOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}



        </div>
    );
};

export default Workbook

const SheetMenu = ({
    sheet, 
    index, 
    handleRenameSheet, 
    handleDeleteSheet, 
    handleDuplicateSheet, 
    maxSheets, 
    sheetsCount, 
    selectedSheetIndex, 
    openSheetTypeDialog
}) => {
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null);

    const updatePosition = (event) => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            setMenuPosition({
                top: rect.bottom + window.scrollY,
                left: Math.max(0, rect.left + window.scrollX - DROPDOWN_WIDTH + rect.width),
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (buttonRef.current) {
                updatePosition();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Menu as="div" className="relative inline-block text-left ml-1">
            {({ open }) => (
                <>
                    <Menu.Button
                        ref={buttonRef}
                        className={`flex items-center rounded-md focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0
                        ${index === selectedSheetIndex 
                            ? 'text-zinc-500 hover:bg-zinc-200' 
                            : 'text-transparent group-hover:text-zinc-500 hover:bg-zinc-200'}
                        `}
                        onClick={updatePosition}
                    >
                        <span className="sr-only">Open options</span>
                        <ChevronDownIcon className="h-5 w-5 m-0.5" aria-hidden="true" />
                    </Menu.Button>
                    {open && (
                        <Portal>
                            <Transition
                                appear
                                show={open}
                                as={React.Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-150"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    style={{
                                        top: `${menuPosition.top}px`,
                                        left: `${menuPosition.left}px`,
                                        width: `${DROPDOWN_WIDTH}px`,
                                    }}
                                >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active ? 'bg-zinc-100 text-zinc-900' : 'text-zinc-700'
                                                } group flex w-full items-center px-4 py-2 text-sm`}
                                                onClick={() => handleRenameSheet(index)}
                                            >
                                                Rename Sheet
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active ? 'bg-zinc-100 text-zinc-900' : 'text-zinc-700'
                                                } group flex w-full items-center px-4 py-2 text-sm`}
                                                onClick={() => handleDeleteSheet(index)}
                                            >
                                                Delete Sheet
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active ? 'bg-zinc-100 text-zinc-900' : 'text-zinc-700'
                                                } group flex w-full items-center px-4 py-2 text-sm`}
                                                onClick={() => handleDuplicateSheet(index)}
                                                disabled={sheetsCount >= maxSheets}
                                            >
                                                Duplicate Sheet
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active ? 'bg-zinc-100 text-zinc-900' : 'text-zinc-700'
                                                } group flex w-full items-center px-4 py-2 text-sm`}
                                                onClick={openSheetTypeDialog}
                                            >
                                                Edit Sheet Type
                                            </button>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Portal>
                    )}
                </>
            )}
        </Menu>
    );
};

const Portal = ({ children }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        return () => setMounted(false);
    }, []);

    return mounted ? ReactDOM.createPortal(children, document.body) : null;
};

const SaveButton = ({ onClick, isSaved, hasEditPermission }) => {
    const SHORTCUT_TEXT = "(⌘/Ctrl + S)";

    let buttonText = "Save";
    if (!hasEditPermission) {
        buttonText = "Download";
    } else if (isSaved) {
        buttonText = "Saved";
    }
    
    return (
        <button
            type="button"
            className={`
                justify-center
                rounded-lg border border-zinc-200
                p-4 py-2 text-sm font-medium 
                transition-colors
                focus:ring-0 focus:ring-offset-0 
                ${isSaved 
                    ? 'bg-zinc-200 text-zinc-700 hover:bg-zinc-700 hover:text-zinc-200' 
                    : 'bg-zinc-800 text-zinc-200 hover:bg-zinc-700 '}
            `}
            onClick={onClick}
            aria-label={`${isSaved ? 'Saved' : 'Save'} ${SHORTCUT_TEXT}`}
        >
            {buttonText}
        </button>
    );
};



const SheetName = ({ sheet, index, isEditing, onRename, setEditingSheetIndex }) => {
    const divRef = useRef(null);

    useEffect(() => {
        if (isEditing && divRef.current) {
            divRef.current.focus();
            // Select all text in the div
            const range = document.createRange();
            range.selectNodeContents(divRef.current);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }, [isEditing]);

    const handleBlur = (e) => {
        console.log(`Sheet name edit finished. New name: "${e.target.textContent}"`);
        onRename(e.target.textContent);
        setEditingSheetIndex(null);
    };

    return (
        <div
            ref={divRef}
            contentEditable={isEditing}
            suppressContentEditableWarning
            onBlur={handleBlur}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.target.blur();
                }
            }}
            onPaste={(e) => {
                e.preventDefault();
                const text = e.clipboardData.getData('text/plain');
                document.execCommand('insertText', false, text.replace(/\n/g, '').slice(0, MAX_NAME_LENGTH));
            }}
            className="outline-none"
        >
            {sheet.name || `Sheet ${index + 1}`}
        </div>
    );
};