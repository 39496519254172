import React, { useState, useEffect, useCallback } from 'react';
import Checkbox from './checkbox';

const Todos = ({ cellContent, editable = false, onChange }) => {
    const [todos, setTodos] = useState([]);

    useEffect(() => {
        const parsedTodos = parseTodos(cellContent);
        if (editable && !parsedTodos.some(todo => todo.text === '')) {
            parsedTodos.push({ isChecked: false, text: '' });
        }
        setTodos(parsedTodos);
    }, [cellContent, editable]);

    const parseTodos = (content) => {
        const regex = /-\[(X|\s)\]\s(.*?)(?=\n-\[|\n$|$)/gs;
        let match;
        const results = [];
        while ((match = regex.exec(content))) {
            results.push({
                isChecked: match[1].trim() === 'X',
                text: match[2].trim()
            });
        }
        return results;
    };

    const handleToggle = useCallback((index) => {
        setTodos(prevTodos => {
            const updatedTodos = prevTodos.map((todo, idx) => 
                idx === index ? { ...todo, isChecked: !todo.isChecked } : todo
            );
            triggerChange(updatedTodos);
            return updatedTodos;
        });
    }, []);

    const handleTextChange = useCallback((text, index) => {
        setTodos(prevTodos => {
            const updatedTodos = prevTodos.map((todo, idx) => 
                idx === index ? { ...todo, text } : todo
            );
            triggerChange(updatedTodos);
            return updatedTodos;
        });
    }, []);

    const handleDelete = useCallback((index) => {
        setTodos(prevTodos => {
            const updatedTodos = prevTodos.filter((_, idx) => idx !== index);
            triggerChange(updatedTodos);
            return updatedTodos;
        });
    }, []);

    const triggerChange = useCallback((updatedTodos) => {
        const nonEmptyTodos = updatedTodos.filter(todo => todo.text.trim() !== '');
        const todoString = nonEmptyTodos.map(todo => `-[${todo.isChecked ? 'X' : ' '}] ${todo.text}`).join('\n');
        onChange(todoString);
    }, [onChange]);

    if (!todos.length && !editable) return null;

    return (
        <div className="flex flex-col gap-1 mt-1">
            {todos.map((todo, index) => (
                <div key={index} className="flex items-center space-x-2">
                    <Checkbox
                        label={todo.text}
                        initialState={todo.isChecked}
                        editable={editable}
                        onToggle={() => handleToggle(index)}
                        onTextChange={(text) => handleTextChange(text, index)}
                    />
                    {editable && (
                        <button
                            onClick={() => handleDelete(index)}
                            className="text-red-500"
                        >
                            Delete
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Todos;
