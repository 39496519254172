import React, { useState, useRef, useEffect } from 'react';

const MAX_COLUMN_NAME_LENGTH = 35;

const NewColumn = ({ onAddColumn, onCancel }) => {
    const [columnName, setColumnName] = useState('');
    const [isEditing, setIsEditing] = useState(true);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (columnName.trim()) {
                onAddColumn(columnName.trim());
            }
        } else if (e.key === 'Escape') {
            onCancel();
        }
    };

    const handleBlur = () => {
        if (columnName.trim()) {
            onAddColumn(columnName.trim());
        } else {
            onCancel();
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.innerText;
        if (newValue.length <= MAX_COLUMN_NAME_LENGTH) {
            setColumnName(newValue);
        } else {
            // Prevent further input by restoring the previous valid content
            e.target.innerText = columnName;
            // Move cursor to the end
            const range = document.createRange();
            const sel = window.getSelection();
            range.selectNodeContents(e.target);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedText = e.clipboardData.getData('text');
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        const availableSpace = MAX_COLUMN_NAME_LENGTH - columnName.length;
        const trimmedText = pastedText.slice(0, availableSpace);
        document.execCommand('insertText', false, trimmedText);
    };

    return (
        <div className="w-72 min-w-72 max-w-72 px-2.5 rounded-lg border border-transparent">
            <div
                ref={inputRef}
                contentEditable
                onInput={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                onPaste={handlePaste}
                className={`w-full py-4 text-lg font-bold leading-tight text-zinc-900 focus:ring-0 focus:outline-none bg-transparent ${
                    isEditing 
                        ? 'whitespace-pre-wrap break-words' 
                        : 'truncate hover:text-clip hover:overflow-visible'
                }`}
                placeholder="Add column"
            />
        </div>
    );
};

export default NewColumn;