import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { findFirstCellOfType, findNthCellOfType, getCellText, convertARGBtoRGBHex } from './itemsUtils';
import Tags from './tags';
import Assignees from './assignees';
import Dates from './dates';
import Todos from './todos';
import VisualId from './visualId';
import CardText from './cardText';
import Hyperlink from './hyperlink';

const Item = ({ item, columnIndex }) => {
    const [itemData, setItemData] = useState({
        id: '',
        title: '',
        description: '',
        tags: '',
        todo: '',
        visual_id: '',
        startDate: '',
        endDate: '',
        assignee: '',
        emoji: '',
        hyperlink: ''
    });

    useEffect(() => {
        setItemData({
            id: item.id,
            title: getCellText(findNthCellOfType(item.contentCells, 'text', 1)),
            description: getCellText(findNthCellOfType(item.contentCells, 'text', 2)),
            tags: getCellText(findFirstCellOfType(item.contentCells, 'tag')),
            todo: getCellText(findFirstCellOfType(item.contentCells, 'todo')),
            visual_id: getCellText(findFirstCellOfType(item.contentCells, 'id')),
            startDate: getCellText(findFirstCellOfType(item.contentCells, 'startDate')),
            endDate: getCellText(findFirstCellOfType(item.contentCells, 'endDate')),
            assignee: getCellText(findFirstCellOfType(item.contentCells, 'assignee')),
            emoji: getCellText(findFirstCellOfType(item.contentCells, 'emoji')),
            hyperlink: getCellText(findFirstCellOfType(item.contentCells, 'hyperlink'))
        });
        console.log(item);
    }, [item]);

    return (
        <div className='flex w-full px-3 py-3'>
            <div className="flex-shrink-0 mr-2">
                <ItemColor color={item.leftBorderColor} />
            </div>
            <div className='flex flex-col flex-grow'>
                {itemData.visual_id && (
                    <div className="flex justify-between">
                        <VisualId cellContent={itemData.visual_id} />
                    </div>
                )}

                <div className="flex items-start text-sm font-normal text-zinc-800">
                    <span className='line-clamp-3 leading-snug'>
                        {itemData.emoji}{itemData.title}
                    </span>
                </div>

                {itemData.description && (<CardText cellContent={itemData.description} />)}

                {(itemData.startDate || itemData.endDate || itemData.todo) && (
                    <div className="flex flex-col pt-2 space-y-1">
                        {(itemData.startDate || itemData.endDate) && (
                            <Dates 
                                startCellContent={itemData.startDate} 
                                endCellContent={itemData.endDate} 
                                onStartChange={(newStartDate) => {
                                    setItemData(prev => ({ ...prev, startDate: newStartDate }));
                                    // Add logic to save the new start date
                                }}
                                onEndChange={(newEndDate) => {
                                    setItemData(prev => ({ ...prev, endDate: newEndDate }));
                                    // Add logic to save the new end date
                                }}
                            />
                        )}
                        
                        {itemData.todo && (
                          <div className="py-1.5">
                            <Todos cellContent={itemData.todo} />
                          </div>
                        )}
                        
                    </div>
                )}

                {(itemData.assignee || itemData.hyperlink) && (
                  <div className="flex pt-2 space-x-2 ">
                    {itemData.assignee && (<Assignees cellContent={itemData.assignee} />)}

                    {itemData.tags && (
                          <div className="flex space-x-1.5">
                            <Tags cellContent={itemData.tags} editable={false} />
                          </div>
                        )}
                    
                    {itemData.hyperlink && (
                      <div className="flex">
                        <Hyperlink url={itemData.hyperlink} text={itemData.hyperlink} />
                        </div>
                        )}
                    
                  </div>
                )}

            </div>
        </div>
    );
};

const Items = ({ items, onEditItem }) => {
    const [itemState, setItemState] = useState(items);

    useEffect(() => {
        setItemState(items);
    }, [items]);

    return (
        <div className="items-container">
            {itemState.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => onEditItem(item)}
                            className="draggable-item rounded-xl bg-white mb-2 text-left border border-zinc-200 shadow-sm"
                        >
                            <Item item={item} />
                        </div>
                    )}
                </Draggable>
            ))}
        </div>
    );
};

export default Items;

const ItemColor = ({ color }) => {
    let backgroundColor = '#D1D5DB'; // Default gray color

    if (color && color !== '') {
        const convertedColor = convertARGBtoRGBHex(color);
        if (convertedColor && convertedColor !== '#FFFFFF' && convertedColor !== '#00000000' && color !== 'rgba(0, 0, 0, 0)') {
            backgroundColor = convertedColor;
        }
    }
  
    return (
        <span className='inline-flex items-center' style={{ verticalAlign: 'top' }}>
            <span 
                className='w-[1.1rem] h-[1.1rem] rounded-full inline-block border-2'
                style={{ 
                    borderColor: backgroundColor,
                    backgroundColor: `${backgroundColor}40`, // 40 is for 25% opacity
                    verticalAlign: 'top' 
                }} 
            />
        </span>
    );
};
