// itemsUtilities.js
import { richTextToHtml } from '../Utilities//RichTextUtils';  // Import utilities

// For unique formatting cells
export const findFirstCellOfType = (cells, type) => {
    return cells.find(cell => cell.type === type);
  };

// For title and description which both rely on plain text
export const findNthCellOfType = (cells, type, nth = 1) => {
    let count = 0;
    for (let cell of cells) {
        if (cell.type === type) {
            count++;
            if (count === nth) {
                return cell;
            }
        }
    }
    return null; // Returns null if no matching cell is found
};
  
export const getCellText = (cell) => {
    if (!cell) return '';

    if (cell.richText) {
        return richTextToHtml(cell.richText);
    } else {
        return cell.text || '';
    }
};

export const convertARGBtoRGBHex = (argb) => {
    if (!argb) {
        return '';
    }
    if (argb === "000000") {
        return ''; // Show black border as gray on UI
    }

    const result = `#${argb.slice(2)}`; // Skip the first two characters (alpha component) and return the rest
    return result; 
};