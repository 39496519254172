import React, { createContext, useContext, useState } from 'react';

const defaultConfig = {
    cellAmount: 3,
    borderStyle: { style: 'thin', color: { argb: '#000000' } },
    margin: 1,
    // Additional default configurations can be added here
};

const ItemConfigContext = createContext(defaultConfig);

export const useItemConfig = () => useContext(ItemConfigContext);

export const ItemConfigProvider = ({ children }) => {
    const [config, setConfig] = useState(defaultConfig);

    return (
        <ItemConfigContext.Provider value={{ config, setConfig }}>
            {children}
        </ItemConfigContext.Provider>
    );
};
